import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { IExchangeRate, INewExchangeRate } from '../../../../shared/models/exchange-rate.model';


const prefix = 'exchange-rates';
const currencies = "exchange-rates/currencies"

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: any, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IExchangeRate[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IExchangeRate>(`${prefix}/${id}`);
    return [data];
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IExchangeRate, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IExchangeRate>(`${prefix}/${id}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewExchangeRate, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCurrenciesEntities = createAsyncThunk(`get-all-${currencies}`, async (fields: any, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IExchangeRate[]>(`${currencies}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  