import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { INewSocialMedia, ISocialMedia } from '../../../../shared/models/socialMedia.model';
import { ISocialMediaFilter } from './SocialMedia';


const prefix = 'social-networks';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: ISocialMediaFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<ISocialMedia[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ISocialMedia>(`${prefix}/${id}`);
    return [data];
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: ISocialMedia, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<ISocialMedia>(`${prefix}/${id}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewSocialMedia, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});