import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { differenceBy } from "lodash";
import { ExchangeRateType } from "../../../../enumeration/paymentType";
import { ICurrencies, IExchangeRate } from "../../../../shared/models/exchange-rate.model";
import { RootState } from "../../../../shared/reducers";
import { IInitialState } from "../../../../shared/shared-interfaces";
import {
  createEntity,
  getCurrenciesEntities,
  getEntities,
  getEntity,
  removeEntity,
  updateEntity
} from "./exchageRate.api";

interface IExchangeRateReducer extends IInitialState {
  fetchCurrenciesSuccess: boolean;
  currenciesList: ICurrencies[];
  updatedRate: IExchangeRate | null;
}

const initialState: IExchangeRateReducer = {
  currenciesList: [],
  updatedRate: null,
  fetchCurrenciesSuccess: false,
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const exchangeRateAdapter = createEntityAdapter<IExchangeRate>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: "exchangeRateSlice",
  initialState: exchangeRateAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.updatedRate = null;
      state.initialState.currenciesList = [];
      state.initialState.fetchCurrenciesSuccess = false;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updatedRate = null;
      state.initialState.fetchCurrenciesSuccess = false;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IExchangeRate[]>>) => {
      exchangeRateAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers["x-total-count"]);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IExchangeRate[]>) => {
      exchangeRateAdapter.setAll(state, payload);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IExchangeRate>) => {
      exchangeRateAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updatedRate = payload;
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.updatedRate = null;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IExchangeRate>) => {
      exchangeRateAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      exchangeRateAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [getCurrenciesEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<ICurrencies[]>>) => {
      state.initialState.currenciesList = payload.data;
      state.initialState.fetchCurrenciesSuccess = true;
      state.initialState.loading = false;
    },
    [getCurrenciesEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchCurrenciesSuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const exchangeRateSelectors = exchangeRateAdapter.getSelectors<RootState>((state) => state.exchangeRate);

const { selectById, selectAll } = exchangeRateAdapter.getSelectors();
const getExchangeRateState = (rootState: RootState) => rootState.exchangeRate;

export const selectEntityById = (id: string) => {
  return createSelector(getExchangeRateState, (state) => selectById(state, id));
};

export const filterUsedCurrencies = () => {
  return createSelector(getExchangeRateState, (state) => {
    const usedCurrencies = selectAll(state).map((entity) => entity.from);
    return differenceBy(state.initialState.currenciesList, usedCurrencies,'code');
    // selectAll(state).filter((entity) => entity.toCode === type)
  });
};

export const getExchangeRateByType = (type: ExchangeRateType) => {
  return createSelector(getExchangeRateState, (state) => selectAll(state).find((entity) => entity.fromCode === type));
};
