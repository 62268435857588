import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../config/axios-interceptor";
import { IDistrictsAddress, IProvincesAddress, IWardsAddress } from "../../../../shared/models/provinces.model";

const provinces = "address/provinces";
const districts = "address/districts";
const wards = "address/wards";

export const getProvincesEntites = createAsyncThunk(`get-${provinces}`, async (field: { country: "VN" }, thunkAPI) => {
  try {
    const params = pickBy(field);
    const result = await axios.get<IProvincesAddress[]>(`${provinces}`, { params });
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getDistrictsEntites = createAsyncThunk(
  `get-${districts}`,
  async (field: { province: string }, thunkAPI) => {
    try {
      const params = pickBy(field);
      const result = await axios.get<IDistrictsAddress[]>(`${districts}`, { params });
      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getWardsEntites = createAsyncThunk(`get-${wards}`, async (field: { district: string }, thunkAPI) => {
  try {
    const params = pickBy(field);
    const result = await axios.get<IWardsAddress[]>(`${wards}`, { params });
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
