import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { last, sortBy } from "lodash";
import { RequestStatus, RequestType } from "../../../../enumeration/request";
import { IAgencyRequest, IRequest, IRequestInfo } from "../../../../shared/models/request.model";
import { RootState } from "../../../../shared/reducers";
import { IInitialState } from "../../../../shared/shared-interfaces";
import {
  createEntity,
  getEntities,
  getHistories,
  getUpgradeInfo, reactiveAgency, removeEntity,
  updateEntities,
  updateEntity,
  updateRequestStatus
} from "./requests.api";

interface IRequestInitialState extends IInitialState {
  reactiveAgencySuccess:boolean;
  fetchHistorySuccess: boolean;
  updateRequestStatusSuccess: boolean;
  updateEntitiesSuccess: boolean;
  histories: IAgencyRequest[];
  upgradeInfo: IRequestInfo | null;
  getUpgradeInfoSuccess: boolean;
}

const initialState: IRequestInitialState = {
  reactiveAgencySuccess:false,
  fetchEntitiesSuccess: false,
  upgradeInfo: null,
  getUpgradeInfoSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  updateRequestStatusSuccess: false,
  updateEntitiesSuccess: false,
  deleteEntitySuccess: false,
  fetchHistorySuccess: false,
  loading: false,
  errorMessage: null,
  histories: [],
  totalItems: 0,
};

export const requestAdapter = createEntityAdapter<IRequest>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: "requestSlice",
  initialState: requestAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.reactiveAgencySuccess = false;
      state.initialState.loading = false;
      state.initialState.upgradeInfo = null;
      state.initialState.getUpgradeInfoSuccess = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.updateRequestStatusSuccess = false;
      state.initialState.fetchHistorySuccess = false;
      state.initialState.updateEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.reactiveAgencySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.getUpgradeInfoSuccess = false;
      state.initialState.updateRequestStatusSuccess = false;
      state.initialState.updateEntitiesSuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.fetchHistorySuccess = false;
    },
    setAll: requestAdapter.setAll,
    updateOne: requestAdapter.updateOne,
    updateMany: requestAdapter.updateMany,
    addOne: requestAdapter.addOne,
    removeOne: requestAdapter.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IRequest[]>>) => {
      state.initialState.totalItems = Number(payload.headers["x-total-count"]);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getHistories.fulfilled.type]: (state, { payload }: PayloadAction<IAgencyRequest[]>) => {
      state.initialState.histories = payload;
      state.initialState.fetchHistorySuccess = true;
      state.initialState.loading = false;
    },
    [getHistories.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchHistorySuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, _: PayloadAction<IRequest>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [updateEntities.fulfilled.type]: (state, _: PayloadAction<undefined>) => {
      state.initialState.updateEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [updateEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitiesSuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IRequest>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [updateRequestStatus.fulfilled.type]: (state, { payload }: PayloadAction<IRequest[]>) => {
      state.initialState.updateRequestStatusSuccess = true;
      state.initialState.loading = false;
    },
    [updateRequestStatus.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateRequestStatusSuccess = false;
    },
    [getUpgradeInfo.fulfilled.type]: (state, { payload }: PayloadAction<IRequestInfo>) => {
      state.initialState.upgradeInfo = payload;
      state.initialState.getUpgradeInfoSuccess = true;
      state.initialState.loading = false;
    },
    [getUpgradeInfo.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.getUpgradeInfoSuccess = false;
    },
    [reactiveAgency.fulfilled.type]: (state, _: PayloadAction<IRequest>) => {
      state.initialState.reactiveAgencySuccess = true;
      state.initialState.loading = false;
    },
    [reactiveAgency.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.reactiveAgencySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne, addOne, removeOne, updateMany } = actions;

export const requestSelectors = requestAdapter.getSelectors<RootState>((state) => state.requests);

const { selectById } = requestAdapter.getSelectors();
const getRequestState = (rootState: RootState) => rootState.requests;

export const selectEntityById = (id: string) => {
  return createSelector(getRequestState, (state) => selectById(state, id));
};

/* 
  This selector selects the lastest request from the current user.
  Since backend doesnt have a way of telling whether the lastest request is confirmed/rejected...
  If the status of the lastest request is NOT Pending, the user CAN create another request.
*/
export const getLastestHistoryRecord = () => {
  return createSelector(getRequestState, ({ initialState }) => {
    return last(sortBy(initialState.histories, "createdDate"));
  });
};

export const checkSentRequestByType = (type: RequestType) => {
  return createSelector(getRequestState, (state) => {
    if (!state.initialState.histories.length) return undefined;
    const history = state.initialState.histories.filter((entity) => entity.type === type);
    if (!history?.length) return false;
    return Boolean(history.find(({ status }) => status !== RequestStatus.REJECT));
  });
};
