import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "../../../../config/axios-interceptor";
import { Roles } from "../../../../enumeration/roles";
import { IMasterAgencyPolicy, INewMasterAgencyPolicy } from "../../../../shared/models/masterAgencyPolicy.model";
import { addOne, removeOne, setAll, updateOne } from "./agencyConfigPolicy.reducer";
import { ICCFilter } from "./AgencyConfigPolicyManagement";
import { IAgencyPolicyConflict, IPolicyByType } from "./agencyConflictPolicy.model";

const prefix = "rewards";

// export const getEntities = createAsyncThunk('get-groups', async (params: IGroupFilterState, thunkAPI) => {
export const getEntities = createAsyncThunk("master-get-rewards", async (field: ICCFilter, thunkAPI) => {
  try {
    //   const result = await axios.get(`${prefix}`, { params });
    const params = _.pickBy(field);
    const result = await axios.get<IMasterAgencyPolicy[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk("master-get-reward", async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IMasterAgencyPolicy>(`${prefix}/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk("master-update-reward", async (body: IMasterAgencyPolicy, thunkAPI) => {
  try {
    const { id } = body;
    const result = await axios.put(`${prefix}/${id}`, body);
    // const result = await axios.put(`${prefix}`, body);
    if (id) {
      thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    }
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk("master-create-reward", async (body: INewMasterAgencyPolicy, thunkAPI) => {
  try {
    const result = await axios.post(`${prefix}`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const removeEntity = createAsyncThunk("master-delete-reward", async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAgencyPolicyConflict = createAsyncThunk(
  "master-get-agencyPolicyConflict",
  async (body: { userIds: string; stageId: string }, thunkAPI) => {
    try {
      const { userIds, stageId } = body;
      const result = await axios.get<IAgencyPolicyConflict[]>(
        `${prefix}/check/reward-user?userIds=${userIds}&stageId=${stageId}`
      );
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAgencyPolicy = createAsyncThunk(
  "master-get-agencyPolicy",
  async (field: { stageId?: string }, thunkAPI) => {
    try {
      const params = _.pickBy(field);
      const result = await axios.get<IPolicyByType>(`policies/current-policy?type=${Roles.AGENCY}`, { params });
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getMasterAgencyPolicy = createAsyncThunk(
  "master-get-masterAgencyPolicy",
  async (field: { stageId?: string }, thunkAPI) => {
    try {
      const params = _.pickBy(field);
      const result = await axios.get<IPolicyByType>(`policies/current-policy?type=${Roles.MASTER_AGENCY}`, { params });
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
