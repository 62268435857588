import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { INotification } from "../../../../shared/models/notification.model";
import { RootState } from "../../../../shared/reducers";
import { IInitialState } from "../../../../shared/shared-interfaces";
import { getEntities, updateEntity } from "./notification.api";

interface INotificationReducer extends IInitialState {
  totalUnseen: number;
}

const initialState: INotificationReducer = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalUnseen: 0,
};

export const notificationsAdapter = createEntityAdapter<INotification>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: "notificationSlice",
  initialState: notificationsAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<INotification[]>>) => {
      notificationsAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers["x-total-count"]);
      state.initialState.totalUnseen = Number(payload.headers["x-total-countunseen"]);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<INotification>) => {
      notificationsAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const notificationsSelectors = notificationsAdapter.getSelectors<RootState>((state) => state.notification);

const { selectById } = notificationsAdapter.getSelectors();
const getNotificationState = (rootState: RootState) => rootState.notification;

export const selectEntityById = (id: string) => {
  return createSelector(getNotificationState, (state) => selectById(state, id));
};
