import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IRecruitment, IRecruitmentPost } from '../../../../../shared/models/recruiment.model';
import { RootState } from '../../../../../shared/reducers';
import { IInitialState } from '../../../../../shared/shared-interfaces';
import { createEntity, getEntities, getEntity, removeEntity, updateEntity } from './recruitment.api';

interface IRecruitmentState extends IInitialState{
  recruitmentPost: IRecruitmentPost |null
}

const initialState: IRecruitmentState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  recruitmentPost:null,
  updateEntitySuccess:false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const recruimentAdapter = createEntityAdapter<IRecruitment>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'recruimentSlice',
  initialState: recruimentAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.recruitmentPost = null;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.errorMessage = null;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IRecruitment[]>>) => {
      recruimentAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IRecruitmentPost>) => {
      state.initialState.recruitmentPost = payload;
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      recruimentAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IRecruitmentPost>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IRecruitmentPost>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const recruimentSelectors = recruimentAdapter.getSelectors<RootState>((state) => state.recruiment);

const { selectById } = recruimentAdapter.getSelectors();
const getRecruimentState = (rootState: RootState) => rootState.recruiment;

export const selectEntityById = (id: string) => {
  return createSelector(getRecruimentState, (state) => selectById(state, id));
};
