import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { Roles } from '../../../../enumeration/roles';
import { ICondition, ICurrentCondition } from '../../../../shared/models/condition.model';
import { RootState } from '../../../../shared/reducers';
import { IInitialState } from '../../../../shared/shared-interfaces';
import {
  createEntity,
  getCurrentCondition,
  getCurrentStageCondition,
  getEntities,
  getEntity,
  getStageConditions,
  removeEntity,
  updateEntity,
} from './condition.api';

interface IConditionState extends IInitialState {
  currentCondition: ICondition | null;
  getCurrentConditionSuccess: boolean;
  currentStageCondition: ICurrentCondition[];
  chosenStageCondition: ICondition[];
  getCurrentStageConditionSuccess: boolean;
  chosenTableType: Roles | undefined;
}

const initialState: IConditionState = {
  currentCondition: null,
  getCurrentConditionSuccess: false,
  currentStageCondition: [],
  chosenStageCondition: [],
  getCurrentStageConditionSuccess: false,
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  chosenTableType: undefined,
};

export const conditionManagementAdapter = createEntityAdapter<ICondition>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'conditionManagementSlice',
  initialState: conditionManagementAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.getCurrentConditionSuccess = false;
      state.initialState.currentCondition = null;
      state.initialState.currentStageCondition = [];
      state.initialState.chosenStageCondition = [];
      state.initialState.getCurrentStageConditionSuccess = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.loading = false;
      state.initialState.getCurrentStageConditionSuccess = false;
      state.initialState.getCurrentConditionSuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    setChosenTableType(state, { payload }: PayloadAction<Roles>) {
      state.initialState.chosenTableType = payload;
    },
    setAll: conditionManagementAdapter.setAll,
    updateOne: conditionManagementAdapter.updateOne,
    addOne: conditionManagementAdapter.addOne,
    removeOne: conditionManagementAdapter.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<any>>) => {
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<undefined>) => {
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<ICondition>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<ICondition>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [getCurrentCondition.fulfilled.type]: (state, { payload }: PayloadAction<ICondition>) => {
      state.initialState.currentCondition = payload;
      state.initialState.getCurrentConditionSuccess = true;
      state.initialState.loading = false;
    },
    [getCurrentCondition.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.initialState.getCurrentConditionSuccess = false;
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
    },
    [getStageConditions.fulfilled.type]: (state, { payload }: PayloadAction<ICondition[]>) => {
      state.initialState.chosenStageCondition = payload;
      state.initialState.loading = false;
    },
    [getStageConditions.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
    },
    [getCurrentStageCondition.fulfilled.type]: (state, { payload }: PayloadAction<ICurrentCondition[]>) => {
      state.initialState.currentStageCondition = payload;
      state.initialState.getCurrentStageConditionSuccess = true;
      state.initialState.loading = false;
    },
    [getCurrentStageCondition.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.initialState.getCurrentStageConditionSuccess = false;
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne, addOne, removeOne, setChosenTableType } = actions;

export const conditionManagementSelectors = conditionManagementAdapter.getSelectors<RootState>(
  (state) => state.conditionManagement
);

const { selectById } = conditionManagementAdapter.getSelectors();
const getConditionManagementState = (rootState: RootState) => rootState.conditionManagement;

export const selectEntityById = (id: string) => {
  return createSelector(getConditionManagementState, (state) => {
    const result = selectById(state, id);
    if (!result) return undefined;
    return { ...result, parentStageId: result.stage?.parentId };
  });
};

export const selectConditionByType = (type: Roles) => {
  return createSelector(getConditionManagementState, (state) => {
    if (!state.initialState.currentStageCondition.length) return undefined;
    return state.initialState.currentStageCondition.find((entity) => entity.type === type);
  });
};

export const selectConditionChosenStageByType = (type: Roles) => {
  return createSelector(getConditionManagementState, (state) => {
    if (!state.initialState.chosenStageCondition.length) return undefined;
    return state.initialState.chosenStageCondition.find((entity) => entity.type === type);
  });
};
