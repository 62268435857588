import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { ICCheck, INewCCheck } from '../../../../shared/models/ccheck.model';
import { ICCFilter } from './CChecking';

import { setAll, updateOne, addOne } from './cchecking.reducer';

const prefix = 'cross-checking';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: ICCFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const result = await axios.get<ICCheck[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntityDetails = createAsyncThunk(`get-entity-details-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<ICCheck>(`${prefix}/${id}`);
    thunkAPI.dispatch(updateOne({ id, changes: result.data }));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: ICCheck | INewCCheck, thunkAPI) => {
  try {
    const { id } = body;
    const result = await axios.put(`${prefix}/${id}/approve`, body);
    if (id) {
      thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    }
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewCCheck, thunkAPI) => {
  try {
    const result = await axios.post(`${prefix}`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const claimReward = createAsyncThunk(`reward-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const result = await axios.put(`${prefix}/${id}/reward`, { id });
    thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
