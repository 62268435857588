import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../../shared/reducers';
import { IInitialState } from '../../../../shared/shared-interfaces';
import { IPolicyByType } from '../../MasterAgency/AgencyConfigPolicyManagement/agencyConflictPolicy.model';
import {
  createEntity,
  getAgencyPolicy,
  getEntities,
  getEntity,
  getMasterAgencyPolicy,
  removeEntity,
  updateEntity,
} from './investorConfigPolicy.api';
import { IInvestorConfig } from './investorConfigPolicy.model';

interface IInvestorConfigPolicy extends IInitialState {
  getAgencyPolicySuccess: boolean;
  agencyPolicy: IPolicyByType | null;
  masterAgencyPolicy: IPolicyByType | null;
  getMasterAgencyPolicySuccess: boolean;
}

const initialState: IInvestorConfigPolicy = {
  fetchEntitiesSuccess: false,
  getMasterAgencyPolicySuccess: false,
  masterAgencyPolicy: null,
  getAgencyPolicySuccess: false,
  agencyPolicy: null,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const investorConfigPolicy = createEntityAdapter<IInvestorConfig>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'investorConfigPolicySlice',
  initialState: investorConfigPolicy.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.getAgencyPolicySuccess = false;
      state.initialState.masterAgencyPolicy = null;
      state.initialState.getMasterAgencyPolicySuccess = false;
      state.initialState.agencyPolicy = null;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    setAll: investorConfigPolicy.setAll,
    updateOne: investorConfigPolicy.updateOne,
    addOne: investorConfigPolicy.addOne,
    removeOne: investorConfigPolicy.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<any>>) => {
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<undefined>) => {
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IInvestorConfig>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IInvestorConfig>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [getAgencyPolicy.fulfilled.type]: (state, { payload }: PayloadAction<IPolicyByType>) => {
      state.initialState.agencyPolicy = payload;
      state.initialState.getAgencyPolicySuccess = true;
      state.initialState.loading = false;
    },
    [getAgencyPolicy.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.getAgencyPolicySuccess = false;
    },
    [getMasterAgencyPolicy.fulfilled.type]: (state, { payload }: PayloadAction<IPolicyByType>) => {
      state.initialState.masterAgencyPolicy = payload;
      state.initialState.getMasterAgencyPolicySuccess = true;
      state.initialState.loading = false;
    },
    [getMasterAgencyPolicy.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.getMasterAgencyPolicySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne, addOne, removeOne } = actions;

export const investorConfigPolicySelectors = investorConfigPolicy.getSelectors<RootState>(
  (state) => state.investorPolicyManagement
);

const { selectById, selectAll } = investorConfigPolicy.getSelectors();
const getInvestorConfigPolicyState = (rootState: RootState) => rootState.investorPolicyManagement;

export const selectEntityById = (id: string) => {
  return createSelector(getInvestorConfigPolicyState, (state) => selectById(state, id));
};

// Since there's always one element, we can safely assume that the element we're looking for is at [0]
export const selectInvestorPolicy = () => {
  return createSelector(getInvestorConfigPolicyState, (state) => selectAll(state)[0]);
};
