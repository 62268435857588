import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../../config/axios-interceptor";
import { IContactContent, INewContactContent } from "../../../../../shared/models/contactContent.model";

const prefix = "contacts";

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: any, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const {data} = await axios.get<IContactContent>(`${prefix}`, { params });
    return [data]
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IContactContent>(`${prefix}/${id}`);
    return [data];
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IContactContent, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IContactContent>(`${prefix}/${id}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewContactContent, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
