import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { TxType } from '../../../../enumeration/investorDetail';
import { Status } from '../../../../enumeration/status';
import { INewUser, IUser } from '../../../../shared/models/authentication.model';
import { ITransferHistory } from '../../../../shared/models/frontOfficeDashboard.model';
import { IOrder } from '../../../../shared/models/order.model';
import { IInvOrderParams, IInvTxParams } from './InvestorDetail';
import { IInvestorMngmentFilter } from './InvestorManagement';
import { addOne, removeOne, setAll, updateOne } from './investorManagement.reducer';

const prefix = 'users/investor';
const userPref = 'users';

export const getEntities = createAsyncThunk(
  `agency-get-all-${prefix}`,
  async (fields: IInvestorMngmentFilter, thunkAPI) => {
    try {
      const params = pickBy(fields);
      const result = await axios.get<IUser[]>(`${prefix}`, { params });
      thunkAPI.dispatch(setAll(result.data));
      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getInvestorOrders = createAsyncThunk(`get-${prefix}-orders`, async (params: IInvOrderParams, thunkAPI) => {
  try {
    const { userId } = params;
    const result = await axios.get<IOrder[]>(`${userPref}/${userId}/orders`, { params });
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IGetInvTxResult {
  result: AxiosResponse<ITransferHistory[]>;
  type: TxType;
}

export const getInvestorsTx = createAsyncThunk(`get-${prefix}-tx`, async (params: IInvTxParams, thunkAPI) => {
  try {
    const { userId } = params;
    const { type } = params;
    const result = await axios.get<ITransferHistory[]>(`${userPref}/${userId}/transactions`, { params });
    return { result, type };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`agency-get-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IUser>(`users/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(
  `agency-update-one-${prefix}`,
  async (body: IUser | INewUser, thunkAPI) => {
    try {
      const { id } = body;
      // const result = await axios.put(`${prefix}/${id}`, body);
      const result = await axios.put(`${prefix}`, body);
      if (id) {
        thunkAPI.dispatch(updateOne({ id, changes: result.data }));
      }
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(`agency-create-one-${prefix}`, async (body: INewUser, thunkAPI) => {
  try {
    const result = await axios.post(`${prefix}`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`agency-delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface IUpateStatusBody {
  id: string;
  status: Status;
}
export const updateEntityStatus = createAsyncThunk('update-agency-status', async (body: IUpateStatusBody, thunkAPI) => {
  try {
    const { id } = body;
    const result = await axios.put<IUser>(`${userPref}/${id}/status/change`, pickBy(body));
    thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
