import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../shared/reducers';
import { getEntities, getCurrentEntity, updateEntity, createEntity, removeEntity } from './miniStages.api';
import { IInitialState } from '../../../../shared/shared-interfaces';
import { AxiosResponse } from 'axios';
import { IMiniStage } from '../../../../shared/models/miniStage.model';

interface IMiniStageState extends IInitialState {
  currentEntity: null | IMiniStage
}

const initialState: IMiniStageState = {
  fetchEntitiesSuccess: false,
  currentEntity: null,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const miniStagesAdapter = createEntityAdapter<IMiniStage>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'miniStageSlice',
  initialState: miniStagesAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    setAll: miniStagesAdapter.setAll,
    updateOne: miniStagesAdapter.updateOne,
    addOne: miniStagesAdapter.addOne,
    removeOne: miniStagesAdapter.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<any>>) => {
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getCurrentEntity.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IMiniStage>>) => {
      state.initialState.currentEntity = payload.data;
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getCurrentEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IMiniStage>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IMiniStage>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne,addOne, removeOne } = actions;

export const miniStageSelectors = miniStagesAdapter.getSelectors<RootState>((state) => state.miniStages);

const { selectById } = miniStagesAdapter.getSelectors();
const getMiniStagesState = (rootState: RootState) => rootState.miniStages;

export const selectEntityById = (id: string) => {
  return createSelector(getMiniStagesState, (state) => selectById(state, id));
};


