import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ITrainingCourse, ITrainingCourseRegistered } from '../../../../shared/models/trainingCourse.model';
import { RootState } from '../../../../shared/reducers';
import { IInitialState } from '../../../../shared/shared-interfaces';
import {
  getEntities,
  getEntity,
  updateEntity,
  createEntity,
  removeEntity,
  getRegisteredEntities,
  registerEntity,
  withdrawEntity,
  payoutEntity,
  ITrainingCoursesRevenue,
  getEntitiesRevenue,
} from './trainingCourses.api';

interface ITrainingCourseInitialState extends IInitialState {
  trainingCoursesRegistered: ITrainingCourseRegistered[];
  trainingCoursesRevenue: ITrainingCoursesRevenue | null;
}

const initialState: ITrainingCourseInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  trainingCoursesRegistered: [],
  trainingCoursesRevenue: null,
};

export const trainingCourseAdapter = createEntityAdapter<ITrainingCourse>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'trainingCourseSlice',
  initialState: trainingCourseAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<ITrainingCourse[]>>) => {
      trainingCourseAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<ITrainingCourse>) => {
      trainingCourseAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<ITrainingCourse>) => {
      trainingCourseAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<ITrainingCourse>) => {
      trainingCourseAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      trainingCourseAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [getRegisteredEntities.fulfilled.type]: (
      state,
      { payload }: PayloadAction<AxiosResponse<ITrainingCourseRegistered[]>>
    ) => {
      state.initialState.trainingCoursesRegistered = payload.data;
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getRegisteredEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [registerEntity.fulfilled.type]: (state, { payload }: PayloadAction<ITrainingCourse>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [registerEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [withdrawEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.initialState.trainingCoursesRegistered = state.initialState.trainingCoursesRegistered.filter(
        (course) => course.trainingCourseId !== payload
      );
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [withdrawEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [payoutEntity.fulfilled.type]: (state, { payload }: PayloadAction<ITrainingCourse>) => {
      trainingCourseAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [payoutEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [getEntitiesRevenue.fulfilled.type]: (state, { payload }: PayloadAction<ITrainingCoursesRevenue>) => {
      state.initialState.trainingCoursesRevenue = payload;
      state.initialState.loading = false;
    },
    [getEntitiesRevenue.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity } = actions;

export const trainingCourseSelectors = trainingCourseAdapter.getSelectors<RootState>((state) => state.trainingCourses);

const { selectById } = trainingCourseAdapter.getSelectors();
const getTrainingCourseState = (rootState: RootState) => rootState.trainingCourses;

export const selectEntityById = (id: string) => {
  return createSelector(getTrainingCourseState, (state) => selectById(state, id));
};
