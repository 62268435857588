import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { IPermsContainers } from '../../../../enumeration/permsContainer';
// import { Permissions } from '../../enumeration/permissions';
// import { Status } from '../../enumeration/status';
import { IGroup, INewGroup } from '../../../../shared/models/auth-group.model';
import { IGroupFilter } from './Groups';
import { setAll, updateOne, addOne, removeOne } from './groups.reducer';

const prefix = 'groups';
const permPrefix = 'authority';

// export const getEntities = createAsyncThunk('get-groups', async (params: IGroupFilterState, thunkAPI) => {
export const getEntities = createAsyncThunk('get-groups', async (fields: IGroupFilter, thunkAPI) => {
    try {
      const params = pickBy(fields);
      const result = await axios.get<IGroup[]> (`${prefix}`, {params});
      thunkAPI.dispatch(setAll(result.data));
      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });

export const getPermissions = createAsyncThunk('get-permissions', async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IPermsContainers>(`${permPrefix}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
  
  export const getEntityPerms = createAsyncThunk('get-group-perms', async (id: number, thunkAPI) => {
    try {
      const result = await axios.get(`${prefix}/${id}`);
      thunkAPI.dispatch(updateOne({ id, changes: result.data }));
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const getEntity = createAsyncThunk('get-group', async (id: string, thunkAPI) => {
    try {
      const {data} = await axios.get<IGroup>(`${prefix}/${id}`);
      const resultArray = [data];
      thunkAPI.dispatch(setAll(resultArray));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const updateEntity = createAsyncThunk('update-group', async (body: IGroup , thunkAPI) => {
    try {
      const { id } = body;
      const {data: changes} = await axios.put(`${prefix}/${id}`, body);
      if (id) {
        thunkAPI.dispatch(updateOne({ id, changes }));
      }
      return changes;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  
  export const createEntity = createAsyncThunk('create-group', async (body: INewGroup, thunkAPI) => {
    try {
      const result = await axios.post<IGroup>(`${prefix}`, body);
      thunkAPI.dispatch(addOne({...result.data }));
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
  export const removeEntity = createAsyncThunk('delete-group', async (id: string, thunkAPI) => {
    try {
      await axios.delete(`${prefix}/${id}`);
      thunkAPI.dispatch(removeOne(id));
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });
