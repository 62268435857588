import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { IMasterAgencyPolicy } from "../../../../shared/models/masterAgencyPolicy.model";
import { RootState } from "../../../../shared/reducers";
import { IInitialState } from "../../../../shared/shared-interfaces";
import {
  createEntity,
  getAgencyPolicy,
  getAgencyPolicyConflict,
  getEntities,
  getEntity,
  getMasterAgencyPolicy,
  removeEntity,
  updateEntity,
} from "./agencyConfigPolicy.api";
import { IAgencyPolicyConflict, IPolicyByType } from "./agencyConflictPolicy.model";

interface IAgencyConfigPolicy extends IInitialState {
  agencyPolicyConflictSuccess: boolean;
  agencyPolicyConflictList: IAgencyPolicyConflict[];
  getAgencyPolicySuccess: boolean;
  agencyPolicy: IPolicyByType | null;
  getMasterAgencyPolicySuccess: boolean;
  masterAgencyPolicy: IPolicyByType | null;
}

const initialState: IAgencyConfigPolicy = {
  fetchEntitiesSuccess: false,
  agencyPolicyConflictSuccess: false,
  getAgencyPolicySuccess: false,
  agencyPolicy: null,
  agencyPolicyConflictList: [],
  getMasterAgencyPolicySuccess: false,
  masterAgencyPolicy: null,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const agencyConfigPolicy = createEntityAdapter<IMasterAgencyPolicy>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: "agencyConfigPolicySlice",
  initialState: agencyConfigPolicy.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.getAgencyPolicySuccess = false;
      state.initialState.agencyPolicy = null;
      state.initialState.agencyPolicyConflictList = [];
      state.initialState.agencyPolicyConflictSuccess = false;
      state.initialState.getMasterAgencyPolicySuccess = false;
      state.initialState.masterAgencyPolicy = null;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    setAll: agencyConfigPolicy.setAll,
    updateOne: agencyConfigPolicy.updateOne,
    addOne: agencyConfigPolicy.addOne,
    removeOne: agencyConfigPolicy.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<any>>) => {
      state.initialState.totalItems = Number(payload.headers["x-total-count"]);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<undefined>) => {
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IMasterAgencyPolicy>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IMasterAgencyPolicy>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [getAgencyPolicyConflict.fulfilled.type]: (state, { payload }: PayloadAction<IAgencyPolicyConflict[]>) => {
      state.initialState.agencyPolicyConflictList = payload;
      state.initialState.agencyPolicyConflictSuccess = true;
      state.initialState.loading = false;
    },
    [getAgencyPolicyConflict.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.agencyPolicyConflictSuccess = false;
    },
    [getAgencyPolicy.fulfilled.type]: (state, { payload }: PayloadAction<IPolicyByType>) => {
      state.initialState.agencyPolicy = payload;
      state.initialState.getAgencyPolicySuccess = true;
      state.initialState.loading = false;
    },
    [getAgencyPolicy.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.getAgencyPolicySuccess = false;
    },
    [getMasterAgencyPolicy.fulfilled.type]: (state, { payload }: PayloadAction<IPolicyByType>) => {
      state.initialState.masterAgencyPolicy = payload;
      state.initialState.getMasterAgencyPolicySuccess = true;
      state.initialState.loading = false;
    },
    [getMasterAgencyPolicy.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.getMasterAgencyPolicySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne, addOne, removeOne } = actions;

export const agencyConfigPolicySelectors = agencyConfigPolicy.getSelectors<RootState>(
  (state) => state.agencyPolicyManagement
);

const { selectById } = agencyConfigPolicy.getSelectors();
const getAgencyConfigPolicyState = (rootState: RootState) => rootState.agencyPolicyManagement;

export const selectEntityById = (id: string) => {
  return createSelector(getAgencyConfigPolicyState, (state) => selectById(state, id));
};
