import axios from 'axios';
import { onResponseError, onResponseSuccess, TIMEOUT } from './axios-interceptor';
import { MANAGEMENT_SITE_URL } from './constants';

// https://redux.js.org/faq/code-structure
// How can I use the Redux store in non-component files?

axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = MANAGEMENT_SITE_URL;

const instance = axios.create({
  baseURL: MANAGEMENT_SITE_URL,
  timeout: TIMEOUT,
});

const onRequestSuccess = (config: any) => {
  const token = localStorage.getItem('keycloak_authentication_token') || sessionStorage.getItem('keycloak_authentication_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

instance.interceptors.request.use(onRequestSuccess);
instance.interceptors.response.use(onResponseSuccess, onResponseError);

export default instance;
