import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { INewUser, ISubAgency } from '../../../../shared/models/authentication.model';
import { ISubAgencyMngmentFilter } from './SubAgencyManagement';
import { addOne, removeOne, setAll, updateOne } from './subAgencyManagement.reducer';

const prefix = 'users/sub-agency';

export const getEntities = createAsyncThunk(
  `sub-agency-get-all-${prefix}`,
  async (fields: ISubAgencyMngmentFilter, thunkAPI) => {
    try {
      const params = pickBy(fields);
      const result = await axios.get<ISubAgency[]>(`${prefix}`, { params });
      thunkAPI.dispatch(setAll(result.data));
      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntity = createAsyncThunk(`sub-agency-get-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<ISubAgency>(`users/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(
  `sub-agency-update-one-${prefix}`,
  async (body: ISubAgency | INewUser, thunkAPI) => {
    try {
      const { id } = body;
      // const result = await axios.put(`${prefix}/${id}`, body);
      const result = await axios.put(`${prefix}`, body);
      if (id) {
        thunkAPI.dispatch(updateOne({ id, changes: result.data }));
      }
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(`sub-agency-create-one-${prefix}`, async (body: INewUser, thunkAPI) => {
  try {
    const result = await axios.post(`${prefix}`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const removeEntity = createAsyncThunk(`sub-agency-delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
