import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../config/axios-interceptor";
import { ISystemGrowth } from "../../../../shared/models/system-growth.model";

const prefix = "reports";

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: any, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<ISystemGrowth[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ISystemGrowth>(`${prefix}/${id}`);
    return [data];
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

