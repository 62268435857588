import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { IAdsContent } from "../../../../../shared/models/adsContent.model";
import { RootState } from "../../../../../shared/reducers";
import { IInitialState } from "../../../../../shared/shared-interfaces";
import { createEntity, getEntities, removeEntity, updateEntity, getEntity } from "./ads.api";

const initialState: IInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const adsAdapter = createEntityAdapter<IAdsContent>({
  selectId: ({ id }) => id,
});
 
const { actions, reducer } = createSlice({
  name: "adsSlice",
  initialState: adsAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    setAll: adsAdapter.setAll,
    updateOne: adsAdapter.updateOne,
    addOne: adsAdapter.addOne,
    removeOne: adsAdapter.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IAdsContent[]>>) => {
      adsAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers["x-total-count"]);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IAdsContent[]>) => {
      adsAdapter.setAll(state, payload);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IAdsContent>) => {
      adsAdapter.updateOne(state, {id: payload.id , changes: payload})
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IAdsContent>) => {
      adsAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      adsAdapter.removeOne(state, payload)
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity} = actions;

export const adsSelectors = adsAdapter.getSelectors<RootState>((state) => state.adsContent);

const { selectById } = adsAdapter.getSelectors();
const getAdsState = (rootState: RootState) => rootState.adsContent;

export const selectEntityById = (id: string) => {
  return createSelector(getAdsState, (state) => selectById(state, id));
};
