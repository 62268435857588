import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../config/axios-interceptor";
import { IBank, INewBank } from "../../../../shared/models/bank.model";
import { IBankFilter } from "./Banks";
import { addOne, removeOne, setAll, updateOne } from "./banks.reducer";

const prefix = "banks";

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IBankFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const result = await axios.get<IBank[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IBank>(`${prefix}/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IBank, thunkAPI) => {
  try {
    const { id } = body;
    const result = await axios.put(`${prefix}/${id}`, body);
    if (id) {
      thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    }
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewBank, thunkAPI) => {
  try {
    const result = await axios.post(`${prefix}`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
