export enum RequestStatus {
  PENDING = 'PENDING',
  APPROVE = 'APPROVE',
  CANCEL = 'CANCEL',
  REJECT = 'REJECT',
}

export const requestStatusArray: RequestStatus[] = [
  RequestStatus.PENDING,
  RequestStatus.APPROVE,
  RequestStatus.REJECT,
  RequestStatus.CANCEL,
];

export const mapRequestStatus: { [key in RequestStatus]: string } = {
  [RequestStatus.PENDING]: 'CHỜ DUYỆT',
  [RequestStatus.APPROVE]: 'ĐÃ DUYỆT',
  [RequestStatus.REJECT]: 'TỪ CHỐI',
  [RequestStatus.CANCEL]: 'HỦY BỎ',
};

export enum RequestType {
  EXPERT = 'EXPERT',
  AGENCY = 'AGENCY',
  OFFICE = 'OFFICE',
}

export const requestTypeArray: RequestType[] = [RequestType.EXPERT, RequestType.AGENCY, RequestType.OFFICE];

export const mapRequestType: { [key in RequestType]: string } = {
    [RequestType.EXPERT]: 'CHUYÊN GIA',
    [RequestType.AGENCY]: 'ĐẠI LÝ',
    [RequestType.OFFICE]: 'VP ĐẠI DIỆN',
  };

export const mapRequestStatusBadge: { [key in RequestStatus]: string } = {
  [RequestStatus.PENDING]: 'info',
  [RequestStatus.APPROVE]: 'success',
  [RequestStatus.REJECT]: 'danger',
  [RequestStatus.CANCEL]: 'danger',
};