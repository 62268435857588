import { createAsyncThunk } from "@reduxjs/toolkit";
import _, { pickBy } from "lodash";
import axios from "../../../../config/axios-interceptor";
import { Roles } from "../../../../enumeration/roles";
import { IPolicyByType } from "../../MasterAgency/AgencyConfigPolicyManagement/agencyConflictPolicy.model";
import { IInvestorConfigPolicyFilter } from "./InvestorConfigPolicy";
import { IInvestorConfig, INewInvestorConfig } from "./investorConfigPolicy.model";
import { addOne, removeOne, setAll, updateOne } from "./investorConfigPolicy.reducer";

const prefix = "rewards";
const prefixCreate = "rewards/agency/investor";
// export const getEntities = createAsyncThunk('get-groups', async (params: IGroupFilterState, thunkAPI) => {
export const getEntities = createAsyncThunk("agency-get-investor-policies", async (fields:IInvestorConfigPolicyFilter, thunkAPI) => {
  try {
    //   const result = await axios.get(`${prefix}`, { params });
    const params = pickBy(fields);
    const result = await axios.get<IInvestorConfig[]>(`${prefix}`,{params});
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk("agency-get-investor-policy", async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IInvestorConfig>(`${prefix}/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(
  "agency-update-investor-policy",
  async (body: IInvestorConfig, thunkAPI) => {
    try {
      const { id } = body;
      const result = await axios.post(`${prefixCreate}`, body);
      // const result = await axios.put(`${prefix}`, body);
      if (id) {
        thunkAPI.dispatch(updateOne({ id, changes: result.data }));
      }
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  "agency-create-investor-policy",
  async (body: INewInvestorConfig, thunkAPI) => {
    try {
      const result = await axios.post(`${prefixCreate}`, body);
      const { id } = result.data;
      thunkAPI.dispatch(addOne({ id, ...result.data }));
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const removeEntity = createAsyncThunk("agency-delete-investor-policy", async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const getAgencyPolicy = createAsyncThunk("investor-get-agency-policy", async (field: { stageId?: string }, thunkAPI) => {
  try {
    const params = _.pickBy(field);
    const result = await axios.get<IPolicyByType>(`policies/current-policy?type=${Roles.AGENCY}`, { params });
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getMasterAgencyPolicy = createAsyncThunk("investor-get-master-agency-policy", async (_, thunkAPI) => {
    try {
      const result = await axios.get<IPolicyByType>(`policies/current-policy?type=${Roles.MASTER_AGENCY}`);
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });


