import { EnhancedStore } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { SERVER_API_URL } from './constants';
import { resetAuthentication } from '../views/authentication/auth.api';

// https://redux.js.org/faq/code-structure
// How can I use the Redux store in non-component files?
let store: any;

export const handleClientErrors = (response: AxiosResponse) => {
  const {message} = response.data
  switch (message) {
    case "useInOtherDevice":
      store.dispatch(resetAuthentication(message));
      break;
    default:
      break;
  }
};

export const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

const instance = axios.create({
  baseURL: SERVER_API_URL,
  timeout: TIMEOUT,
});

const onRequestSuccess = (config: any) => {
  const token = localStorage.getItem('authentication_token') || sessionStorage.getItem('authentication_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

export const onResponseSuccess = (response: any) => response;
export const onResponseError = (err: any) => {
  const status = err.status || (err.response ? err.response.status : 0);
    if (status >= 400 && status < 500) {
      handleClientErrors(err.response);
    }
    return Promise.reject(err);
};

instance.interceptors.request.use(onRequestSuccess);
instance.interceptors.response.use(onResponseSuccess, onResponseError);

export const injectStore = (_store: EnhancedStore) => {
  store = _store;
};

export default instance;
