import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../config/axios-interceptor';

const prefix = 'transaction-fees';

export interface IConfigFee {
  fee: string;
}

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IConfigFee>(`${prefix}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: IConfigFee, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
