import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';

import { AxiosResponse } from 'axios';

import { IInitialState } from '../../../../shared/shared-interfaces';
import { RootState } from '../../../../shared/reducers';
import { IEmail } from '../../../../shared/models/email.model';
import { getEntities, updateEntity, createEntity, removeEntity, getEntity, getUsers } from './emails.api';
import { IUser } from '../../../../shared/models/authentication.model';
import { returnOrganizationName } from '../../../../shared/helper';


interface IEmailInititalState extends IInitialState {
  users: IUser[];
}

const initialState: IEmailInititalState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  users: []
};

export const emailAdapter = createEntityAdapter<IEmail>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'emailContentSlice',
  initialState: emailAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.users = [];
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    }
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IEmail[]>>) => {
      emailAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IEmail[]>) => {
      emailAdapter.setAll(state, payload);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IEmail>) => {
      emailAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IEmail>) => {
      emailAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      emailAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [getUsers.fulfilled.type]: ({initialState}, { payload }: PayloadAction<IUser[]>) => {
      initialState.fetchEntitiesSuccess = true;
      initialState.users = payload;
      initialState.loading = false;
    },
    [getUsers.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const emailContentSelectors = emailAdapter.getSelectors<RootState>((state) => state.emails);

const { selectById } = emailAdapter.getSelectors();
const getEmailContentState = (rootState: RootState) => rootState.emails;

export const selectEntityById = (id: string) => {
  return createSelector(getEmailContentState, (state) => selectById(state, id));
};

export const selectUserOptions = () => {
  return createSelector(getEmailContentState, ({initialState}) => {
    const {users} = initialState;
    if (!users.length) return [];
    return users.map(user => ({
      ...user,
      label: `${returnOrganizationName(user)} - ${user.email}` ,
      value: user.email
    }))
  })
}