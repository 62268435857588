import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { TxType } from '../../../../enumeration/investorDetail';
import { IUser } from '../../../../shared/models/authentication.model';
import { ITransferHistory } from '../../../../shared/models/frontOfficeDashboard.model';
import { IOrder } from '../../../../shared/models/order.model';
import { RootState } from '../../../../shared/reducers';
import { IInitialState } from '../../../../shared/shared-interfaces';
import { createEntity, getEntities, getEntity, getInvestorOrders, getInvestorsTx, IGetInvTxResult, removeEntity, updateEntity, updateEntityStatus } from './investorManagement.api';

interface IInvActvtiesDtls {
  totalSent: number,
  totalReceived: number,
  totalOrders: number,
  sents: ITransferHistory[],
  receiveds: ITransferHistory[],
  orders: IOrder[],

}

interface IUserManagementInitialState extends IInitialState {
  investorActivitiesDetail: IInvActvtiesDtls
}

const initialState: IUserManagementInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  investorActivitiesDetail: {
    totalSent: 0,
    totalOrders: 0,
    totalReceived: 0,
    sents: [],
    receiveds: [],
    orders: [],
  }
};

export const investorManagementAdapter = createEntityAdapter<IUser>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'investorManagementSlice',
  initialState: investorManagementAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    resetInvestorDetails(state) {
      state.initialState.investorActivitiesDetail = {
        totalSent: 0,
        totalOrders: 0,
        totalReceived: 0,
        sents: [],
        receiveds: [],
        orders: [],
      }
    },
    setAll: investorManagementAdapter.setAll,
    updateOne: investorManagementAdapter.updateOne,
    addOne: investorManagementAdapter.addOne,
    removeOne: investorManagementAdapter.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IUser[]>>) => {
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getInvestorOrders.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IOrder[]>>) => {
      state.initialState.investorActivitiesDetail.totalOrders = Number(payload.headers['x-total-count']);
      state.initialState.investorActivitiesDetail.orders = payload.data;
    },
    [getInvestorOrders.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
    },
    [getInvestorsTx.fulfilled.type]: (state, { payload }: PayloadAction<IGetInvTxResult>) => {
      const {type, result} = payload;
      if (type === TxType.RECEIVER) {
        state.initialState.investorActivitiesDetail.totalReceived = Number(result.headers['x-total-count']);
        state.initialState.investorActivitiesDetail.receiveds = result.data;
      } else if (type === TxType.SENDER) {
        state.initialState.investorActivitiesDetail.totalSent = Number(result.headers['x-total-count']);
        state.initialState.investorActivitiesDetail.sents = result.data;
      }
    },
    [getInvestorsTx.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<undefined>) => {
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },

    [updateEntityStatus.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntityStatus.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetInvestorDetails, resetEntity, setAll, updateOne, addOne, removeOne } = actions;

export const investorManagementSelectors = investorManagementAdapter.getSelectors<RootState>(
  (state) => state.investorManagement
);

const { selectById } = investorManagementAdapter.getSelectors();
const getInvestorManagementState = (rootState: RootState) => rootState.investorManagement;

export const selectEntityById = (id: string) => {
  return createSelector(getInvestorManagementState, (state) => selectById(state, id));
};
