import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../../config/axios-interceptor';
import { IBankAccount, INewBankAccount } from '../../../../../shared/models/bank.model';
import { IBankAccountFilter } from './BankAccounts';
import { addOne, removeOne, setAll, updateOne } from './bankAccounts.reducer';

const prefix = 'bank-accounts';
const currentAccount = 'bank-accounts/current';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IBankAccountFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const result = await axios.get<IBankAccount[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IBankAccount>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IBankAccount, thunkAPI) => {
  try {
    const { id } = body;
    const result = await axios.put(`${prefix}/${id}`, body);
    if (id) {
      thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    }
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewBankAccount, thunkAPI) => {
  try {
    const result = await axios.post(`${prefix}`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAccountBank = createAsyncThunk('get-account-bank', async (_, thunkAPI) => {
  try {
    const result = await axios.get(`account/banks`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getParentEntity = createAsyncThunk(`get-parnet-${prefix}`, async (_, thunkAPI) => {
  try {
    const result = await axios.get<IBankAccount>(`${prefix}/parent`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCurrentEntities = createAsyncThunk(
  `get-all-current-${prefix}`,
  async (fields: IBankAccountFilter, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<IBankAccount[]>(`${currentAccount}`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCurrentEntity = createAsyncThunk(`get-single-current-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IBankAccount>(`${currentAccount}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateCurrentEntity = createAsyncThunk(
  `update-one-current-${prefix}`,
  async (body: IBankAccount, thunkAPI) => {
    try {
      const { id } = body;
      const { data } = await axios.put(`${currentAccount}/${id}`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createCurrentEntity = createAsyncThunk(
  `create-one-current-${prefix}`,
  async (body: INewBankAccount, thunkAPI) => {
    try {
      const { data } = await axios.post(`${currentAccount}`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const removeCurrentEntity = createAsyncThunk(`delete-one-current-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${currentAccount}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
