import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { pickBy } from 'lodash';
import axios from '../../../../../config/axios-interceptor';
import { IAdsContent, INewAdsContent } from '../../../../../shared/models/adsContent.model';
import { IAvatar } from '../../../../frontOffice/dashboard/dashboard.api';
import { IAdsFilter } from './Ads';

const prefix = 'advertisement';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IAdsFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IAdsContent[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IAdsContent>(`${prefix}/${id}`);
    return [data];
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const uploadMedia = async (media: any): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append('file', media);
    const { data }: AxiosResponse<IAvatar[]> = await axios.post(`avatar`, formData);
    return data[0].url;
  } catch (error: any) {
    return error;
  }
};

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IAdsContent, thunkAPI) => {
  try {
    const { id, mediaUrl } = body;
    const media = mediaUrl ? await uploadMedia(mediaUrl) : undefined;
    const requestBody = {
      ...body,
      mediaUrl: media,
    };
    const result = await axios.put<IAdsContent>(`${prefix}/${id}`, requestBody);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewAdsContent, thunkAPI) => {
  try {
    const { mediaUrl } = body;
    const media = mediaUrl ? await uploadMedia(mediaUrl) : undefined;
    const requestBody = {
      ...body,
      mediaUrl: media,
    };
    const result = await axios.post(`${prefix}`, requestBody);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
