import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../config/axios-interceptor';
import { IAgencyMasterSocial, ISocialGroup } from '../../../../shared/models/socialGroup.model';

const prefix = 'social-groups';

export const getEntities = createAsyncThunk(`get-${prefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<ISocialGroup[]>(`${prefix}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntities = createAsyncThunk(`update-${prefix}`, async (body: ISocialGroup[], thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const getAdminEntities = createAsyncThunk(`get-admin-${prefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<ISocialGroup[]>(`${prefix}/company`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAgencyMasterEntities = createAsyncThunk(`get-agency-master-${prefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IAgencyMasterSocial>(`${prefix}/agency-master`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
