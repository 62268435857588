export enum Permissions {
  CREATE_BACKEND = 'CREATE_BACKEND',
  UPDATE_BACKEND = 'UPDATE_BACKEND',
  VIEW_LIST_BACKEND = 'VIEW_LIST_BACKEND',
  CREATE_MASTER = 'CREATE_MASTER',
  UPDATE_MASTER = 'UPDATE_MASTER',
  VIEW_LIST_MASTER = 'VIEW_LIST_MASTER',
  VIEW_LIST_INVESTOR = 'VIEW_LIST_INVESTOR',
  VIEW_LIST_AGENCY = 'VIEW_LIST_AGENCY',
  VIEW_LIST_EXPERT = 'VIEW_LIST_EXPERT',
  CHANGE_ACTIVE_USER = 'CHANGE_ACTIVE_USER',

  DELETE_BLOG = 'DELETE_BLOG',
  UPDATE_BLOG = 'UPDATE_BLOG',
  VIEW_LIST_BLOG = 'VIEW_LIST_BLOG',

  VIEW_LIST_OFFICE = 'VIEW_LIST_OFFICE',

  VIEW_FRANCHISE = 'VIEW_FRANCHISE',
  CREATE_FRANCHISE = 'CREATE_FRANCHISE',
  UPDATE_FRANCHISE = 'UPDATE_FRANCHISE',
  DELETE_FRANCHISE = 'DELETE_FRANCHISE',

  CREATE_GROUP = 'CREATE_GROUP',
  UPDATE_GROUP = 'UPDATE_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  VIEW_LIST_GROUP = 'VIEW_LIST_GROUP',

  CREATE_CONTENT = 'CREATE_CONTENT',
  UPDATE_CONTENT = 'UPDATE_CONTENT',
  DELETE_CONTENT = 'DELETE_CONTENT',
  VIEW_LIST_CONTENT = 'VIEW_LIST_CONTENT',

  CREATE_STAGE = 'CREATE_STAGE',
  UPDATE_STAGE = 'UPDATE_STAGE',
  DELETE_STAGE = 'DELETE_STAGE',
  VIEW_LIST_STAGE = 'VIEW_LIST_STAGE',

  VIEW_LIST_CROSS_CHECKING = 'VIEW_LIST_CROSS_CHECKING',
  APPROVE_CROSS_CHECKING = 'APPROVE_CROSS_CHECKING',

  CREATE_SOCIAL_NETWORK = 'CREATE_SOCIAL_NETWORK',
  UPDATE_SOCIAL_NETWORK = 'UPDATE_SOCIAL_NETWORK',
  DELETE_SOCIAL_NETWORK = 'DELETE_SOCIAL_NETWORK',
  VIEW_LIST_SOCIAL_NETWORK = 'VIEW_LIST_SOCIAL_NETWORK',

  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_AGENCY = 'ROLE_AGENCY',
  ROLE_INVESTOR = 'ROLE_INVESTOR',
  ROLE_MASTER_AGENCY = 'ROLE_MASTER_AGENCY',
  ROLE_EXPERT = 'ROLE_EXPERT',
  ROLE_BACKEND = 'ROLE_BACKEND',

  CREATE_CONDITION = 'CREATE_CONDITION',
  UPDATE_CONDITION = 'UPDATE_CONDITION',
  DELETE_CONDITION = 'DELETE_CONDITION',
  VIEW_LIST_CONDITION = 'VIEW_LIST_CONDITION',

  CREATE_BANK = 'CREATE_BANK',
  UPDATE_BANK = 'UPDATE_BANK',
  DELETE_BANK = 'DELETE_BANK',
  VIEW_LIST_BANK = 'VIEW_LIST_BANK',

  CREATE_POLICY = 'CREATE_POLICY',
  UPDATE_POLICY = 'UPDATE_POLICY',
  DELETE_POLICY = 'DELETE_POLICY',
  VIEW_LIST_POLICY = 'VIEW_LIST_POLICY',

  CREATE_PERIOD = 'CREATE_PERIOD',
  UPDATE_PERIOD = 'UPDATE_PERIOD',
  DELETE_PERIOD = 'DELETE_PERIOD',
  VIEW_LIST_PERIOD = 'VIEW_LIST_PERIOD',

  UPDATE_UPGRADE = 'UPDATE_UPGRADE',
  DELETE_UPGRADE = 'DELETE_UPGRADE',
  VIEW_LIST_UPGRADE = 'VIEW_LIST_UPGRADE',

  CREATE_RATIO = 'CREATE_RATIO',
  UPDATE_RATIO = 'UPDATE_RATIO',
  DELETE_RATIO = 'DELETE_RATIO',
  VIEW_LIST_RATIO = 'VIEW_LIST_RATIO',

  VIEW_STATISTICS = 'VIEW_STATISTICS',

}

export const mapPermissions: { [key in Permissions]: string } = {
  
  [Permissions.VIEW_LIST_AGENCY]: 'XEM ĐẠI LÝ',
  [Permissions.VIEW_LIST_BACKEND]: 'XEM NGƯỜI DÙNG BACKEND',
  [Permissions.CREATE_BACKEND]: 'TẠO NGƯỜI DÙNG BACKEND',
  [Permissions.UPDATE_BACKEND]: 'CẬP NHẬT NGƯỜI DÙNG BACKEND',
  [Permissions.VIEW_LIST_MASTER]: 'XEM TỔNG ĐẠI LÝ',
  [Permissions.CREATE_MASTER]: 'TẠO TỔNG ĐẠI LÝ',
  [Permissions.UPDATE_MASTER]: 'CẬP NHẬT THÔNG TIN TỔNG ĐẠI LÝ',
  [Permissions.CHANGE_ACTIVE_USER]: 'CẬP NHẬT TRẠNG THÁI NGƯỜI DÙNG',
  [Permissions.VIEW_LIST_INVESTOR]: 'XEM NHÀ ĐẦU TƯ',

  [Permissions.VIEW_LIST_EXPERT]: 'XEM CHUYÊN GIA',
  [Permissions.DELETE_BLOG]: 'XÓA BLOG CHUYÊN GIA',
  [Permissions.UPDATE_BLOG]: 'CẬP NHẬT BLOG CHUYÊN GIA',
  [Permissions.VIEW_LIST_BLOG]: 'XEM DANH SÁCH BLOG CHUYÊN GIA',

  [Permissions.VIEW_LIST_OFFICE]: 'XEM VĂN PHÒNG ĐẠI DIỆN',

  [Permissions.VIEW_FRANCHISE]: 'XEM SÀN NHƯỢNG QUYỀN',
  [Permissions.UPDATE_FRANCHISE]: 'CẬP NHẬT SÀN NHƯỢNG QUYỀN',
  [Permissions.DELETE_FRANCHISE]: 'XÓA SÀN NHƯỢNG QUYỀN',
  [Permissions.CREATE_FRANCHISE]: 'TẠO SÀN NHƯỢNG QUYỀN',

  [Permissions.CREATE_GROUP]: 'TẠO NHÓM QUYỀN',
  [Permissions.UPDATE_GROUP]: 'CẬP NHẬT NHÓM QUYỀN',
  [Permissions.DELETE_GROUP]: 'XÓA NHÓM QUYỀN',
  [Permissions.VIEW_LIST_GROUP]: 'XEM NHÓM QUYỀN',

  [Permissions.CREATE_CONTENT]: 'TẠO NỘI DUNG',
  [Permissions.UPDATE_CONTENT]: 'CẬP NHẬT NỘI DUNG',
  [Permissions.DELETE_CONTENT]: 'XÓA NỘI DUNG',
  [Permissions.VIEW_LIST_CONTENT]: 'XEM NỘI DUNG',

  [Permissions.CREATE_STAGE]: 'TẠO GIAI ĐOẠN',
  [Permissions.UPDATE_STAGE]: 'CẬP NHẬT GIAI ĐOẠN',
  [Permissions.DELETE_STAGE]: 'XÓA GIAI ĐOẠN',
  [Permissions.VIEW_LIST_STAGE]: 'XEM GIAI ĐOẠN',

  [Permissions.APPROVE_CROSS_CHECKING]: 'XỬ LÝ ĐỐI SOÁT',
  [Permissions.VIEW_LIST_CROSS_CHECKING]: 'XEM ĐỐI SOÁT',
  
  [Permissions.CREATE_SOCIAL_NETWORK]: 'TẠO MẠNG XÃ HỘI',
  [Permissions.UPDATE_SOCIAL_NETWORK]: 'CẬP NHẬT MẠNG XÃ HỘI',
  [Permissions.DELETE_SOCIAL_NETWORK]: 'XÓA MẠNG XÃ HỘI',
  [Permissions.VIEW_LIST_SOCIAL_NETWORK]: 'XEM MẠNG XÃ HỘI',

  [Permissions.ROLE_ADMIN]: 'QUẢN TRỊ VIÊN',
  [Permissions.ROLE_AGENCY]: 'ĐẠI LÝ',
  [Permissions.ROLE_INVESTOR]: 'NHÀ ĐẦU TƯ',
  [Permissions.ROLE_MASTER_AGENCY]: 'TỔNG ĐẠI LÝ',
  [Permissions.ROLE_EXPERT]: 'CHUYÊN GIA',
  [Permissions.ROLE_BACKEND]: 'BACKEND',

  [Permissions.CREATE_CONDITION]: 'TẠO ĐIỀU KIỆN',
  [Permissions.UPDATE_CONDITION]: 'CẬP NHẬT ĐIỀU KIỆN',
  [Permissions.DELETE_CONDITION]: 'XÓA ĐIỀU KIỆN',
  [Permissions.VIEW_LIST_CONDITION]: 'XEM ĐIỀU KIỆN',

  [Permissions.CREATE_BANK]: 'TẠO NGÂN HÀNG',
  [Permissions.UPDATE_BANK]: 'CẬP NHẬT NGÂN HÀNG',
  [Permissions.DELETE_BANK]: 'XÓA NGÂN HÀNG',
  [Permissions.VIEW_LIST_BANK]: 'XEM NGÂN HÀNG',

  [Permissions.CREATE_PERIOD]: 'TẠO KỲ',
  [Permissions.UPDATE_PERIOD]: 'CẬP NHẬT KỲ',
  [Permissions.DELETE_PERIOD]: 'XÓA KỲ',
  [Permissions.VIEW_LIST_PERIOD]: 'XEM KỲ',

  [Permissions.CREATE_RATIO]: 'TẠO TỶ GIÁ',
  [Permissions.UPDATE_RATIO]: 'CẬP NHẬT TỶ GIÁ',
  [Permissions.DELETE_RATIO]: 'XÓA TỶ GIÁ',
  [Permissions.VIEW_LIST_RATIO]: 'XEM TỶ GIÁ',

  [Permissions.VIEW_STATISTICS]: 'XEM THỐNG KÊ',

  [Permissions.UPDATE_UPGRADE]: 'CẬP NHẬT YÊU CẦU',
  [Permissions.DELETE_UPGRADE]: 'XÓA YÊU CẦU',
  [Permissions.VIEW_LIST_UPGRADE]: 'XEM YÊU CẦU',

  [Permissions.CREATE_POLICY]: 'TẠO CHÍNH SÁCH',
  [Permissions.UPDATE_POLICY]: 'CẬP NHẬT CHÍNH SÁCH',
  [Permissions.DELETE_POLICY]: 'XÓA CHÍNH SÁCH',
  
  [Permissions.VIEW_LIST_POLICY]: 'XEM CHÍNH SÁCH',
};
