import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { IMiniStage } from '../../../../shared/models/miniStage.model';
import { IStage, INewStage } from '../../../../shared/models/stage.model';
import { IStageFilter } from '../stages/Stages';

import { setAll, updateOne, addOne, removeOne } from './miniStages.reducer';

const prefix = 'stages/periods';
const stagesPref = 'stages';
const currentStgPref = 'stages/current-period';


export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IStageFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const result = await axios.get<IMiniStage[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCurrentEntity = createAsyncThunk(`get-current-${prefix}`, async (_, thunkAPI) => {
  try {
    const result = await axios.get<IMiniStage>(`${currentStgPref}`);
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-mini-${stagesPref}`, async (body: IStage | INewStage, thunkAPI) => {
  try {
    const { id } = body;
    const result = await axios.put(`${stagesPref}/${id}`, body);
    if (id) {
      thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    }
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-mini-${stagesPref}`, async (body: INewStage, thunkAPI) => {
  try {
    const result = await axios.post(`${stagesPref}`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-mini-${stagesPref}`, async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IMiniStage>(`${stagesPref}/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${stagesPref}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
