import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ITradeOffice } from '../../../../../shared/models/tradeOffice.model';
import { RootState } from '../../../../../shared/reducers';
import { IInitialState } from '../../../../../shared/shared-interfaces';
import {
  createEntity,
  getEntities,
  getEntity,
  getEntityByAgentId,
  removeEntity,
  updateEntity,
  updateRequestStatus,
  updateStatus,
} from './tradeOffice.api';

interface ITradeOfficeApprovalInitialState extends IInitialState {
  updateRequestStatusSuccess: boolean;
  updateStatusSuccess: boolean;
}

const initialState: ITradeOfficeApprovalInitialState = {
  updateRequestStatusSuccess: false,
  updateStatusSuccess: false,
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const tradeOfficeApprovalAdapter = createEntityAdapter<ITradeOffice>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'tradeOfficeApprovalSlice',
  initialState: tradeOfficeApprovalAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.updateStatusSuccess = false;
      state.initialState.updateRequestStatusSuccess = false;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateRequestStatusSuccess = false;
      state.initialState.updateStatusSuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<ITradeOffice[]>>) => {
      tradeOfficeApprovalAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<ITradeOffice>) => {
      tradeOfficeApprovalAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<ITradeOffice>) => {
      tradeOfficeApprovalAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<ITradeOffice>) => {
      tradeOfficeApprovalAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      tradeOfficeApprovalAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [updateRequestStatus.fulfilled.type]: (state, { payload }: PayloadAction<ITradeOffice>) => {
      tradeOfficeApprovalAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateRequestStatusSuccess = true;
      state.initialState.loading = false;
    },
    [updateRequestStatus.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateRequestStatusSuccess = false;
    },
    [updateStatus.fulfilled.type]: (state, { payload }: PayloadAction<ITradeOffice>) => {
      tradeOfficeApprovalAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateStatusSuccess = true;
      state.initialState.loading = false;
    },
    [updateStatus.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateRequestStatusSuccess = false;
    },
    [getEntityByAgentId.fulfilled.type]: (state, { payload }: PayloadAction<ITradeOffice[]>) => {
      tradeOfficeApprovalAdapter.setAll(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getEntityByAgentId.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const tradeOfficeApprovalSelectors = tradeOfficeApprovalAdapter.getSelectors<RootState>(
  (state) => state.tradeOfficeApproval
);

const { selectById } = tradeOfficeApprovalAdapter.getSelectors();
const getTradeOfficeApprovalState = (rootState: RootState) => rootState.tradeOfficeApproval;

export const selectEntityById = (id: string) => {
  return createSelector(getTradeOfficeApprovalState, (state) => selectById(state, id));
};
