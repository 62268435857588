import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../config/axios-interceptor";
import { INotification } from "../../../../shared/models/notification.model";
import { IParams } from "../../../../shared/shared-interfaces";


const prefix = "notifications";

export const getEntities = createAsyncThunk(`get-${prefix}`, async (field: IParams, thunkAPI) => {
  try {
    const params = pickBy(field);
    const result = await axios.get<INotification[]>(`${prefix}`, { params });
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-${prefix}`, async (id:string, thunkAPI) => {
  try {
    const result = await axios.put(`${prefix}/${id}/seen`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});