import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { IAsset, IRawAssetOverview } from '../../../../shared/models/asset.model';
import { IAssetFilter } from '../../MasterAgency/Asset/Asset';
import { convertToFloat } from './helper';


const prefix = 'tokens';

export const getAssets = createAsyncThunk(`get-${prefix}`, async (fields: IAssetFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const result = await axios.get<IAsset[]>(`${prefix}`, {params});
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAssetOverview = createAsyncThunk(`get-overview-${prefix}`, async (_, thunkAPI) => {
  try {
    const {data} = await axios.get<IRawAssetOverview>(`${prefix}/bonus`);
    return convertToFloat(data);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
