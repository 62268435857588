import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../config/axios-interceptor';
import { IOrder, INewOrder } from '../../../../shared/models/order.model';
import { INewManualOrder } from './ManualOrder';
import { IOrderFilter, IUpdateOrdersStatus } from './Order';

import { setAll, updateOne, addOne, removeOne, updateMany } from './order.reducer';


const prefix = 'orders';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (params: IOrderFilter, thunkAPI) => {
  try {
    const result = await axios.get<IOrder[]>(`${prefix}`, {params});
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntitiesStatus = createAsyncThunk(`update-many-${prefix}-status`, async (body: IUpdateOrdersStatus, thunkAPI) => {
  try {
    const {data} = await axios.put<IOrder[]>(`${prefix}/status`, body);
    const updateObjects = data.map((e) => ({ id: e.id, changes: e }));
    thunkAPI.dispatch(updateMany(updateObjects));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IOrder>(`${prefix}/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(
  `update-one-${prefix}`,
  async (body: IOrder | INewOrder, thunkAPI) => {
    try {
      const { id } = body;
      const result = await axios.put(`${prefix}/${id}`, body);
      if (id) {
        thunkAPI.dispatch(updateOne({ id, changes: result.data }));
      }
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewManualOrder, thunkAPI) => {
  try {
    const result = await axios.post(`${prefix}/agency-create`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

