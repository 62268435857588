import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../shared/reducers';
import { getEntities, createEntity, updateEntity, removeEntity, getEntity, updateEntitiesStatus } from './order.api';
import { IInitialState } from '../../../../shared/shared-interfaces';
import { AxiosResponse } from 'axios';
import { IOrder } from '../../../../shared/models/order.model';
import { OrderStatus } from '../../../../enumeration/order';


interface IOrderInitialState extends IInitialState {
  updateEntitiesSuccess: boolean
}

const initialState: IOrderInitialState = {
  fetchEntitiesSuccess: false,
  updateEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const orderAdapter = createEntityAdapter<IOrder>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'investorOrderSlice',
  initialState: orderAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.updateEntitiesSuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.updateEntitiesSuccess = false;
    },
    setAll: orderAdapter.setAll,
    updateOne: orderAdapter.updateOne,
    addOne: orderAdapter.addOne,
    removeOne: orderAdapter.removeOne,
    updateMany: orderAdapter.updateMany,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<any>>) => {
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<undefined>) => {
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IOrder>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [updateEntitiesStatus.fulfilled.type]: (state, { payload }: PayloadAction<undefined>) => {
      state.initialState.updateEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [updateEntitiesStatus.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitiesSuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IOrder>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne, addOne, removeOne, updateMany } = actions;

export const orderSelectors = orderAdapter.getSelectors<RootState>((state) => state.orders);

const { selectById, selectAll } = orderAdapter.getSelectors();
const getOrderState = (rootState: RootState) => rootState.orders;

export const selectEntityById = (id: string) => {
  return createSelector(getOrderState, (state) => selectById(state, id));
};


export const selectOrdersByStatus = (...statusArr: OrderStatus[]) => {
  return createSelector(getOrderState, (state) =>
    selectAll(state).filter((entity) => statusArr.includes(entity.status))
  );
};
