import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IBankAccount } from '../../../../../shared/models/bank.model';
import { RootState } from '../../../../../shared/reducers';
import { IInitialState } from '../../../../../shared/shared-interfaces';
import {
  createCurrentEntity,
  createEntity,
  getAccountBank,
  getCurrentEntities,
  getCurrentEntity,
  getEntities,
  getEntity,
  getParentEntity,
  removeCurrentEntity,
  removeEntity,
  updateCurrentEntity,
  updateEntity,
} from './bankAccounts.api';

interface IBankAccountState extends IInitialState {
  accountBanks: IBankAccount[];
  getAccountBanksSuccess: boolean;
  parentBanksAccount: IBankAccount | null;
  getParentBankAccountSuccess: boolean;
}

const initialState: IBankAccountState = {
  parentBanksAccount: null,
  getParentBankAccountSuccess: false,
  fetchEntitiesSuccess: false,
  accountBanks: [],
  getAccountBanksSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const bankAccountAdapter = createEntityAdapter<IBankAccount>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'bankAccountSlice',
  initialState: bankAccountAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.parentBanksAccount = null;
      state.initialState.getParentBankAccountSuccess = false;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.accountBanks = [];
      state.initialState.getAccountBanksSuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.getParentBankAccountSuccess = false;
      state.initialState.getAccountBanksSuccess = false;
      state.initialState.deleteEntitySuccess = false;
    },
    setAll: bankAccountAdapter.setAll,
    updateOne: bankAccountAdapter.updateOne,
    addOne: bankAccountAdapter.addOne,
    removeOne: bankAccountAdapter.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IBankAccount[]>>) => {
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      bankAccountAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
    [getAccountBank.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount[]>) => {
      state.initialState.accountBanks = payload;
      state.initialState.getAccountBanksSuccess = true;
      state.initialState.loading = false;
    },
    [getAccountBank.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.getAccountBanksSuccess = false;
      state.initialState.loading = false;
    },
    [getParentEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      state.initialState.parentBanksAccount = payload;
      state.initialState.getParentBankAccountSuccess = true;
      state.initialState.loading = false;
    },
    [getParentEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.getParentBankAccountSuccess = false;
      state.initialState.loading = false;
    },
    [getCurrentEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IBankAccount[]>>) => {
      bankAccountAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getCurrentEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getCurrentEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      bankAccountAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    },
    [getCurrentEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [updateCurrentEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      bankAccountAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateCurrentEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createCurrentEntity.fulfilled.type]: (state, { payload }: PayloadAction<IBankAccount>) => {
      bankAccountAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createCurrentEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeCurrentEntity.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      bankAccountAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeCurrentEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne, addOne, removeOne } = actions;

export const bankAccountSelectors = bankAccountAdapter.getSelectors<RootState>((state) => state.bankAccounts);

const { selectById } = bankAccountAdapter.getSelectors();
const getBankAccountState = (rootState: RootState) => rootState.bankAccounts;

export const selectEntityById = (id: string) => {
  return createSelector(getBankAccountState, (state) => selectById(state, id));
};
