import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { Status } from '../../../../enumeration/status';
import { IUser } from '../../../../shared/models/authentication.model';
import { IOfficeManagementFilter } from './OfficeManagement';
import { setAll, updateOne } from './officeManagement.reducer';

const prefix = '/franchises'; 

export const getEntities = createAsyncThunk('get-offices', async (params: IOfficeManagementFilter, thunkAPI) => {
  try {
    //   const result = await axios.get(`${prefix}`, { params });
    const result = await axios.get<IUser[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk('get-office', async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IUser>(`users/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface IUpateStatusBody {
  id: string;
  status: Status;
}

export const updateEntityStatus = createAsyncThunk('update-office-status', async (body: IUpateStatusBody, thunkAPI) => {
  try {
    const { id } = body;
    const result = await axios.put<IUser>(`${prefix}/${id}/status`, pickBy(body));
    thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});