import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { IAgncyInvstrStats, IExpertOfficeStats } from '../../../../shared/models/statisticStats.model';
import { RootState } from '../../../../shared/reducers';
import { getExpertStatistics } from './expertGrowth.api';
import { getAgencyInvestorStatistics } from './growthStatistics.api';
import { getOfficeStatistics } from './officeGrowth.api';

 
interface IInitialState {
  fetchSuccess: boolean;
  loading: boolean;
  errorMessage: string | null;
  agcyInvstrStats: IAgncyInvstrStats | null;
  expertOfficeStats: IExpertOfficeStats | null;
}

const initialState: IInitialState = {
  fetchSuccess: false,
  loading: false,
  errorMessage: null,
  agcyInvstrStats: null,
  expertOfficeStats: null
};


const { actions, reducer } = createSlice({
  name: 'growthStatSlice',
  initialState: initialState,
  reducers: {
    fetching(state) {
      state.loading = true;
    },
    resetAll(state) {
      state = initialState;
    },
  },
  extraReducers: {
    [getAgencyInvestorStatistics.fulfilled.type]: (state, { payload }: PayloadAction<IAgncyInvstrStats>) => {
        state.agcyInvstrStats = payload;
      state.loading = false;
      state.fetchSuccess = true;
    },
    [getAgencyInvestorStatistics.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload.message;
      state.loading = false;
      state.fetchSuccess = false;
    },
    [getExpertStatistics.fulfilled.type]: (state, { payload }: PayloadAction<IExpertOfficeStats>) => {
      state.expertOfficeStats = payload;
      state.loading = false;
      state.fetchSuccess = true;
    },
    [getExpertStatistics.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload.message;
      state.loading = false;
      state.fetchSuccess = false;
    },
    [getOfficeStatistics.fulfilled.type]: (state, { payload }: PayloadAction<IExpertOfficeStats>) => {
      state.expertOfficeStats = payload;
      state.loading = false;
      state.fetchSuccess = true;
    },
    [getOfficeStatistics.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload.message;
      state.loading = false;
      state.fetchSuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll } = actions;


const getStatisticState = (rootState: RootState) => rootState.growthStatistics;

export const totalDaysFromStart = (fromDayOne: boolean) => {
  return createSelector(getStatisticState, ({ agcyInvstrStats }) => {
    if (!agcyInvstrStats || !agcyInvstrStats.currentStage) return undefined;
    const dateToCompare = fromDayOne ? dayjs(agcyInvstrStats.firstMiniStage.dueDate) : dayjs(agcyInvstrStats.currentStage.dueDate);
    const currentDate = dayjs();
    return currentDate.diff(dateToCompare, 'day') ;
  });
};

export const totalDaysFrStartFrExprtOfice = (fromDayOne: boolean) => {
  return createSelector(getStatisticState, ({ expertOfficeStats }) => {
    if (!expertOfficeStats || !expertOfficeStats.currentStage) return undefined;
    const dateToCompare = fromDayOne ? dayjs(expertOfficeStats.firstMiniStage.dueDate) : dayjs(expertOfficeStats.currentStage.dueDate);
    const currentDate = dayjs();
    return currentDate.diff(dateToCompare, 'day') ; 
  })
}
