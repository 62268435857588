export enum Roles {
  MASTER_AGENCY = 'MASTER_AGENCY',
  AGENCY = 'AGENCY',
  INVESTOR = 'INVESTOR',
  ADMIN = 'ADMIN',
  AGENCY_INVESTOR = 'AGENCY_INVESTOR',
  MASTER_INVESTOR = 'MASTER_INVESTOR',
  EXPERT = 'EXPERT',
  OFFICE = 'OFFICE',
  FRANCHISE = 'FRANCHISE',
  EXPERT_INVESTOR = 'EXPERT_INVESTOR',
  EXPERT_OFFICE = 'EXPERT_OFFICE',
  EXPERT_OFFICE_INVESTOR = 'EXPERT_OFFICE_INVESTOR',
  OFFICE_INVESTOR = 'OFFICE_INVESTOR',
  BACKEND = 'BACKEND',
  // The backend guy thinks that Upgrade(Condtion) and Roles enumerations are mergable, so deal with it.
  INVESMENT = 'INVESMENT',
  BONUS = 'BONUS',
  //
  TRADE = 'TRADE',
  EXPERT_TRADE = 'EXPERT_TRADE',
  EXPERT_OFFICE_TRADE = 'EXPERT_OFFICE_TRADE',
  OFFICE_TRADE = 'OFFICE_TRADE',
  //
  TRADE_INVESTOR = 'TRADE_INVESTOR',
  EXPERT_TRADE_INVESTOR = 'EXPERT_TRADE_INVESTOR',
  OFFICE_TRADE_INVESTOR = 'OFFICE_TRADE_INVESTOR',
  EXPERT_OFFICE_TRADE_INVESTOR = 'EXPERT_OFFICE_TRADE_INVESTOR',
}

export const rolesArray: Roles[] = [Roles.MASTER_AGENCY, Roles.AGENCY, Roles.INVESTOR, Roles.ADMIN];

export const agencyRolesArray: Roles[] = [
  Roles.AGENCY,
  Roles.EXPERT,
  Roles.OFFICE,
  Roles.TRADE,
  Roles.EXPERT_OFFICE,
  Roles.EXPERT_TRADE,
  Roles.OFFICE_TRADE,
  Roles.EXPERT_OFFICE_TRADE,
  //
  Roles.AGENCY_INVESTOR,
  Roles.EXPERT_INVESTOR,
  Roles.OFFICE_INVESTOR,
  Roles.TRADE_INVESTOR,
  Roles.EXPERT_OFFICE_INVESTOR,
  Roles.EXPERT_TRADE_INVESTOR,
  Roles.OFFICE_TRADE_INVESTOR,
  Roles.EXPERT_OFFICE_TRADE_INVESTOR,
];

export const ableRegisterREMRolesArray: Roles[] = [...agencyRolesArray, Roles.INVESTOR];

export const mapRoles: { [key in Roles]: string } = {
  [Roles.ADMIN]: 'QUẢN TRỊ VIÊN',
  [Roles.AGENCY]: 'ĐẠI LÝ',
  [Roles.INVESTOR]: 'NHÀ ĐẦU TƯ',
  [Roles.MASTER_AGENCY]: 'TỔNG ĐẠI LÝ',
  [Roles.AGENCY_INVESTOR]: 'NHÀ ĐẦU TƯ ĐẠI LÝ',
  [Roles.MASTER_INVESTOR]: 'NHÀ ĐẦU TƯ TỔNG ĐẠI LÝ',
  [Roles.EXPERT]: 'CHUYÊN GIA',
  [Roles.EXPERT_OFFICE]: 'VĂN PHÒNG ĐẠI DIỆN',
  [Roles.OFFICE]: 'VĂN PHÒNG ĐẠI DIỆN',
  [Roles.EXPERT_OFFICE_INVESTOR]: 'VĂN PHÒNG ĐẠI DIỆN',
  [Roles.EXPERT_INVESTOR]: 'NHÀ ĐẦU TƯ CHUYÊN GIA',
  [Roles.OFFICE_INVESTOR]: 'VĂN PHÒNG ĐẠI DIỆN',
  [Roles.FRANCHISE]: 'SÀN NHƯỢNG QUYỀN',
  [Roles.BACKEND]: 'NGƯỜI DÙNG HỆ THỐNG',
  [Roles.INVESMENT]: 'HẠN MỨC ĐẦU TƯ',
  [Roles.BONUS]: 'MỨC THƯỞNG TỐI THIỂU',
  [Roles.TRADE]: 'VĂN PHÒNG HỖ TRỢ',
  [Roles.EXPERT_TRADE]: 'VĂN PHÒNG HỖ TRỢ',
  [Roles.OFFICE_TRADE]: 'VĂN PHÒNG HỖ TRỢ',
  [Roles.EXPERT_OFFICE_TRADE]: 'VĂN PHÒNG HỖ TRỢ',

  [Roles.TRADE_INVESTOR]: 'VĂN PHÒNG HỖ TRỢ',
  [Roles.EXPERT_TRADE_INVESTOR]: 'VĂN PHÒNG HỖ TRỢ',
  [Roles.OFFICE_TRADE_INVESTOR]: 'VĂN PHÒNG HỖ TRỢ',
  [Roles.EXPERT_OFFICE_TRADE_INVESTOR]: 'VĂN PHÒNG HỖ TRỢ',
};
