import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy, sortBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { INewStage, IStage } from '../../../../shared/models/stage.model';
import { IStageFilter } from './Stages';

import { setAll, updateOne, addOne, removeOne } from './stages.reducer';

const prefix = 'stages';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IStageFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const result = await axios.get<IStage[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(sortBy(result.data, 'dueDate')));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IStage>(`${prefix}/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IStage | INewStage, thunkAPI) => {
  try {
    const { id } = body;
    const result = await axios.put(`${prefix}/${id}`, body);
    if (id) {
      thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    }
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewStage, thunkAPI) => {
  try {
    const result = await axios.post(`${prefix}`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
