import { combineReducers } from '@reduxjs/toolkit';
import container from '../../containers/backOffice/reducer';
import authentication from '../../views/authentication/auth.reducer';
import accountManagement from '../../views/backOffice/Admin/accountManagement/account.reducer';
import tradeOfficeApproval from '../../views/backOffice/Admin/approvalManagement/tradeOfficeApproval/tradeOffice.reducer';
import banks from '../../views/backOffice/Admin/bank/banks.reducer';
import bankAccounts from '../../views/backOffice/Admin/companyAccounts/bankAccounts/bankAccounts.reducer';
import wallets from '../../views/backOffice/Admin/companyAccounts/blockchainWallets/wallets.reducer';
import tradeCondition from '../../views/backOffice/Admin/conditionManagement/accountUpgrade/TradeConditionManagement/tradeCondition.reducer';
import conditionManagement from '../../views/backOffice/Admin/conditionManagement/condition.reducer';
import configFee from '../../views/backOffice/Admin/configFee/configfee.reducer';
import adsContent from '../../views/backOffice/Admin/content/ads/ads.reducer';
import contacts from '../../views/backOffice/Admin/content/contact/contact.reducer';
import events from '../../views/backOffice/Admin/content/events/events.reducer';
import exchangeContent from '../../views/backOffice/Admin/content/exchanges/exchanges.reducer';
import faqsContent from '../../views/backOffice/Admin/content/faq/faqs.reducer';
import fieldsContent from '../../views/backOffice/Admin/content/fields/fields.reducer';
import recruiment from '../../views/backOffice/Admin/content/recruitment/recruitment.reducer';
import emails from '../../views/backOffice/Admin/emails/emails.reducer';
import exchangeRate from '../../views/backOffice/Admin/exchangeRate/exchangeRate.reducer';
import groups from '../../views/backOffice/Admin/groups/groups.reducer';
import miniStages from '../../views/backOffice/Admin/miniStages/miniStages.reducer';
import officeManagement from '../../views/backOffice/Admin/officeManagement/officeManagement.reducer';
import adminPolicies from '../../views/backOffice/Admin/policies/policies.reducer';
import socialMedia from '../../views/backOffice/Admin/socialMedia/socialMedia.reducer';
import stages from '../../views/backOffice/Admin/stages/stages.reducer';
import systemGrowth from '../../views/backOffice/Admin/systemGrowth/systemGrowth.reducer';
import supports from '../../views/backOffice/Admin/userGuide/userGuide.reducer';
import investorPolicyManagement from '../../views/backOffice/Agency/investorConfigPolicy/investorConfigPolicy.reducer';
import agencyUpperPolicies from '../../views/backOffice/Agency/managementPolicy/managementPolicy.reducer';
import orders from '../../views/backOffice/Agency/order/order.reducer';
import agencyPolicyManagement from '../../views/backOffice/MasterAgency/AgencyConfigPolicyManagement/agencyConfigPolicy.reducer';
import agencyManagement from '../../views/backOffice/shared/AgencyManagement/agencyManagement.reducer';
import assets from '../../views/backOffice/shared/Asset/asset.reducer';
import cchecking from '../../views/backOffice/shared/CChecking/cchecking.reducer';
import expert from '../../views/backOffice/shared/Expert/expert.reducer';
import expertBlogs from '../../views/backOffice/shared/ExpertBlogManagement/expertBlogs.reducer';
import expertManagement from '../../views/backOffice/shared/ExpertManagement/expertManagement.reducer';
import growthStatistics from '../../views/backOffice/shared/GrowthStatistics/growthStatistics.reducer';
import investorManagement from '../../views/backOffice/shared/InvManagement/investorManagement.reducer';
import notification from '../../views/backOffice/shared/NotificationManagement/notification.reducer';
import provinces from '../../views/backOffice/shared/Province/provinces.reducer';
import requests from '../../views/backOffice/shared/Requests/requests.reducer';
import socialGroups from '../../views/backOffice/shared/socialGroups/socialGroup.reducer';
import subAgencyManagement from '../../views/backOffice/shared/SubAgencyManagement/subAgencyManagement.reducer';
import userDashboard from '../../views/frontOffice/dashboard/dashboard.reducer';
import trainingProgram from '../../views/backOffice/Admin/trainingProgram/trainingProgram.reducer';
import trainingCourses from '../../views/backOffice/shared/TrainingCourses/trainingCourses.reducer';
// import { RootState } from '../../store';

const rootReducer = combineReducers({
  container,
  authentication,
  userDashboard,
  groups,
  accountManagement,
  agencyManagement,
  stages,
  socialGroups,
  miniStages,
  adminPolicies,
  subAgencyManagement,
  agencyPolicyManagement,
  investorPolicyManagement,
  bankAccounts,
  orders,
  conditionManagement,
  investorManagement,
  expertManagement,
  agencyUpperPolicies,
  cchecking,
  banks,
  systemGrowth,
  faqsContent,
  fieldsContent,
  recruiment,
  requests,
  assets,
  notification,
  provinces,
  socialMedia,
  wallets,
  events,
  contacts,
  supports,
  growthStatistics,
  expertBlogs,
  exchangeRate,
  officeManagement,
  exchangeContent,
  emails,
  adsContent,
  expert,
  configFee,
  tradeCondition,
  tradeOfficeApproval,
  trainingProgram,
  trainingCourses,
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
