import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../../config/axios-interceptor';
import { RequestStatus } from '../../../../../enumeration/request';
import { Status } from '../../../../../enumeration/status';
import { INewTradeOffice, ITradeOffice } from '../../../../../shared/models/tradeOffice.model';
import { IParams } from '../../../../../shared/shared-interfaces';
import { uploadAvatar } from '../../../../frontOffice/dashboard/dashboard.api';

const prefix = 'office-registrations';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IParams | undefined, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<ITradeOffice[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ITradeOffice>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: ITradeOffice, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<ITradeOffice>(`${prefix}/${id}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewTradeOffice, thunkAPI) => {
  try {
    const { logo } = body;
    const logoUrl = logo ? await uploadAvatar(logo) : undefined;
    const requestBody = {
      ...body,
      logo: logoUrl,
    };

    const { data } = await axios.post(`${prefix}`, pickBy(requestBody));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface ITradeRequest {
  id: string;
  requestStatus: RequestStatus;
}

export const updateRequestStatus = createAsyncThunk(
  `update-request-status-${prefix}`,
  async (body: ITradeRequest, thunkAPI) => {
    try {
      const { data } = await axios.put<any[]>(`${prefix}/${body.id}/request-status`, body);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface ITradeStatus {
  id: string;
  status: Status;
}

export const updateStatus = createAsyncThunk(
  `update-status-${prefix}`,
  async ({ id, status }: ITradeStatus, thunkAPI) => {
    try {
      const { data } = await axios.put<any[]>(`${prefix}/${id}/status`, { status });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getEntityByAgentId = createAsyncThunk(`get-trade-office-info`, async (agentId: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ITradeOffice>(`${prefix}/${agentId}/my-registrations`);
    return [data];
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
