import { createAsyncThunk } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import axios from "../../../../config/axios-interceptor";
import { Roles } from "../../../../enumeration/roles";
import {
  IAgencyRequest,
  INewRequest,
  IRequest,
  IRequestInfo,
  IRequestUpdate
} from "../../../../shared/models/request.model";
import { IParams } from "../../../../shared/shared-interfaces";
import { addOne, removeOne, setAll, updateMany, updateOne } from "./requests.reducer";

const prefix = "upgrades";

interface IRequestFilter extends IParams {}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IRequestFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const result = await axios.get<IRequest[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IRequest>(`${prefix}/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const updateEntityPromise = (entity: IRequest): Promise<IRequest> => {
  const { id } = entity;
  return new Promise((resolve, reject) => {
    axios
      .put<IRequest>(`${prefix}/${id}`, entity)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IRequest, thunkAPI) => {
  try {
    const { id } = body;
    // const result = await axios.put<IRequest>(`${prefix}/${id}`, body);
    const changes = await updateEntityPromise(body);

    thunkAPI.dispatch(updateOne({ id, changes }));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntities = createAsyncThunk(`update-many-${prefix}`, async (body: IRequest[], thunkAPI) => {
  try {
    const updatePromises: Promise<IRequest>[] = body.map((e) => updateEntityPromise(e));
    const data = await Promise.all(updatePromises);
    const updateObjects = data.map((e) => ({ id: e.id, changes: e }));
    thunkAPI.dispatch(updateMany(updateObjects));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface ICreateBody extends INewRequest {}

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: ICreateBody, thunkAPI) => {
  try {
    const { data } = await axios.post<IRequest>(`${prefix}`, body);
    thunkAPI.dispatch(addOne({ ...data }));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getHistories = createAsyncThunk(`get-history-${prefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IAgencyRequest[]>(`${prefix}/histories`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateRequestStatus = createAsyncThunk(`update-status-${prefix}`, async (body: IRequestUpdate, thunkAPI) => {
  try {
    const { data } = await axios.put<IRequest[]>(`${prefix}/approve`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getUpgradeInfo = createAsyncThunk(`get-info-${prefix}`, async (type: Roles, thunkAPI) => {
  try {
    const params = {
      type,
    };
    const { data } = await axios.get<IRequestInfo>(`${prefix}/agency`, { params });
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const reactiveAgency = createAsyncThunk(`reactive-agency-${prefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}/re-active`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
