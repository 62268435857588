import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from "lodash";
import axios from '../../config/axios-interceptor';
import axiosKeyCloak from '../../config/axios-interceptor-keycloak';
import { IUpdateBody, uploadAvatar } from '../frontOffice/dashboard/dashboard.api';
export interface IAuthenticateBody {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export interface IAuthenticateOTP {
  id: string;
  otp: string;
}

export interface IRegisterBody {
  body: {
    imageUrl: string;
    firstName: string;
    lastName: string;
    email: string;
    login: string;
    phone: string;
    password: string;
    referencer: string | null;
    idCard: string;
  };
  avatar: any;
}

interface ICreateAccount {
  id: string;
  login: string;
  newPassword: string;
  otp: string;
}

export const login = createAsyncThunk('login', async (body: IAuthenticateBody, thunkAPI) => {
  try {
    const result = await axios.post(`authenticate`, body);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const otpAuthenticate = createAsyncThunk('otpAuthenticate', async (body: IAuthenticateOTP, thunkAPI) => {
  try {
    const result = await axios.post(`verify`, body);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const register = createAsyncThunk('register', async (body: IRegisterBody, thunkAPI) => {
  try {
    if (body.avatar) {
      const formData = new FormData();
      formData.append('file', body.avatar);
      const avatarUrlRequest = await axios.post(`avatar`, formData);
      const requestBody = { ...body.body, imageUrl: avatarUrlRequest?.data[0]?.url };
      const result = await axios.post(`register`, requestBody);
      return result.data;
    } else {
      const result = await axios.post(`register`, body.body);
      return result.data;
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const authenticate = createAsyncThunk('verify', async (_, thunkAPI) => {
  try {
    const result = await axios.get(`account`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const logout = createAsyncThunk('logout', async (_, thunkAPI) => {
  try {
    await axios.post(`logout`);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const resetAuthentication = createAsyncThunk('resetAuthentication', async (errMsg: string, thunkAPI) => {
  try {
    return errMsg;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// export const resetAuthentication = createAsyncThunk("resetAuthentication", (errMsg: string) => errMsg);

export const createAccount = createAsyncThunk('createAccount', async (body: ICreateAccount, thunkAPI) => {
  try {
    const result = await axios.post(`account/finish`, body);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const sendFirebaseToken = createAsyncThunk('send-fb-token', async (body: { token: string }, thunkAPI) => {
  try {
    const result = await axios.post(`account/update-fcm`, body);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const verifyZaloOTP = createAsyncThunk('verifyOTP', async (body: IAuthenticateOTP, thunkAPI) => {
  try {
    const result = await axios.post(`verify/zalo`, body);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IVerifyZaloByNewPhone {
  id: string;
  phone: string;
  password: string;
  otpCurrentPhone: number | undefined;
  otpNewPhone: number | undefined;
}

export interface ISendOtpToCurrPhone {
  id: string;
  isPhone: boolean;
}

export const verifyZaloByNewPhone = createAsyncThunk('verifyZaloByNewPhone', async (body: IVerifyZaloByNewPhone, thunkAPI) => {
  try {
    const result = await axios.post(`verify/new-zalo`, body);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const sendOtpToCurrPhone = createAsyncThunk(
  'sendOtpToCurrPhone',
  async (body: ISendOtpToCurrPhone, thunkAPI) => {
    try {
      const result = await axios.post(`resend-otp`, body);
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const accountUpdate = createAsyncThunk("accountUpdate", async (updateBody: IUpdateBody, thunkAPI) => {
  try {
    const { avatar, currUser, body, password } = updateBody;
    const avatarUrl = avatar ? await uploadAvatar(avatar) : undefined;
    const requestBody = {
      ...currUser,
      ...body,
      imageUrl: avatarUrl,
      password: password
    };
    const { data } = await axios.post(`account`, pickBy(requestBody));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface ISendOtpToUpdatePhoneOrEmail {
  id: string;
  newPhone?: string;
  newEmail?: string;
}

export const sendOtpToUpdatePhoneOrEmail = createAsyncThunk(
  'send-otp-to-update-phone-or-email',
  async (body: ISendOtpToUpdatePhoneOrEmail, thunkAPI) => {
    try {
      const result = await axios.post(`resend-update-account`, pickBy(body));
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const loginKeyCloak = createAsyncThunk('login-key-cloak', async (body: IAuthenticateBody, thunkAPI) => {
  try {
    const result = await axiosKeyCloak.post(`sso/anft`, body);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});