import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { capitalize } from 'lodash';
import { mapPermissions } from '../../../../enumeration/permissions';
import { IPermsContainers, mapPermsContainer } from '../../../../enumeration/permsContainer';
import { IGroup } from '../../../../shared/models/auth-group.model';
import { RootState } from '../../../../shared/reducers';
import { IInitialState } from '../../../../shared/shared-interfaces';
import { createEntity, getEntities, getEntity, getPermissions, removeEntity, updateEntity } from './groups.api';

interface IGroupInitialState extends IInitialState {
  permissions: IPermsContainers;
}

const initialState: IGroupInitialState = {
  fetchEntitiesSuccess: false,
  permissions: [],
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const groupsAdapter = createEntityAdapter<IGroup>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'groupSlice',
  initialState: groupsAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    setAll: groupsAdapter.setAll,
    updateOne: groupsAdapter.updateOne,
    addOne: groupsAdapter.addOne,
    removeOne: groupsAdapter.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<any>>) => {
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getPermissions.fulfilled.type]: (state, { payload }: PayloadAction<IPermsContainers>) => {
      state.initialState.permissions = payload;
      state.initialState.loading = false;
    },
    [getPermissions.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<undefined>) => {
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IGroup>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IGroup>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne, addOne, removeOne } = actions;

export const groupSelectors = groupsAdapter.getSelectors<RootState>((state) => state.groups);

const { selectById } = groupsAdapter.getSelectors();
const getGroupState = (rootState: RootState) => rootState.groups;

// export const selectEntityById = (id: string) => {
//   return createSelector(getGroupState, (state) => {
//     const entity = selectById(state, id);
//     if (!entity || !entity.authorities) return undefined;
//     const entityWithMappedValueLabelAuthorities: IGroup = {
//       ...entity,
//       authorities: entity.authorities.map(({ name }) => ({ label: name, value: name, name })),
//     };
//     return entityWithMappedValueLabelAuthorities;
//   });
// };

export const selectEntityById = (id: string) => {
  return createSelector(getGroupState, (state) => selectById(state, id));
};

// https://codesandbox.io/s/g3fg9?module=/example.tsx&file=/docs/data.ts:4725-4738
// Convert to grouped options

export const createGroupedPermsOptionsReactSelect = () => {
  return createSelector(getGroupState, (state) =>
    state.initialState.permissions.map(({ children, parent }) => ({
      label: mapPermsContainer[parent],
      options: children.map((e) => ({ label: capitalize(mapPermissions[e]), value: e, name: e })),
    }))
  );
};
