import { Permissions } from './permissions';


enum PermsContainer {
    USER = "USER",
    GROUP = "GROUP",
    CONTENT = "Content",
    STAGE = "STAGE",
    CROSSCHECKING = "CROSSCHECKING",
    SOCIALNETWORK = "SOCIALNETWORK",
    CONDITION = "Condition",
    POLICY = "Policy",
    BANK = "Bank",
    PERIOD = "Period",
    GENERAL = "GENERAL",
    RATIO = "RATIO",   
    UPDGRADE = "Upgrade",   
    Blog = "Blog",   
}

export interface IPermContainer {
    parent: PermsContainer,
    children: Permissions[]
}

export type IPermsContainers = IPermContainer[]

export const mapPermsContainer: { [key in PermsContainer]: string} =  {
    [PermsContainer.USER]: "NHÓM QUYỀN QUẢN TRỊ NGƯỜI DÙNG",
    [PermsContainer.GROUP]: "NHÓM QUYỀN QUẢN TRỊ VAI TRÒ",
    [PermsContainer.STAGE]: "NHÓM QUYỀN QUẢN TRỊ GIAI ĐOẠN",
    [PermsContainer.PERIOD]: "NHÓM QUYỀN QUẢN TRỊ KỲ",
    [PermsContainer.CROSSCHECKING]: "NHÓM QUYỀN QUẢN TRỊ ĐỐI SOÁT",
    [PermsContainer.SOCIALNETWORK]: "NHÓM QUYỀN QUẢN TRỊ MẠNG XÃ HỘI",
    [PermsContainer.CONDITION]: "NHÓM QUYỀN ĐIỀU KIỆN",
    [PermsContainer.POLICY]: "NHÓM QUYỀN CHÍNH SÁCH",
    [PermsContainer.BANK]: "NHÓM QUYỀN NGÂN HÀNG & TÀI KHOẢN THỤ HƯỞNG",
    [PermsContainer.CONTENT]: "NHÓM QUYỀN NỘI DUNG",
    [PermsContainer.GENERAL]: "NHÓM QUYỀN CHUNG",
    [PermsContainer.RATIO]: "NHÓM QUYỀN TỶ GIÁ",
    [PermsContainer.UPDGRADE]: "NHÓM QUYỀN YÊU CẦU",
    [PermsContainer.Blog]: "NHÓM QUYỀN BLOG CHUYÊN GIA",
}


