import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, HTML_DATE_TIME_INPUT_FORMAT, TOKEN_NAME } from '../config/constants';
import { INotificationMapping, INotificationRoute, Notifications } from '../enumeration/notification';
import { Roles } from '../enumeration/roles';
import { Side } from '../enumeration/side';
import { Status } from '../enumeration/status';
import { IAccount } from './models/admin-account.model';
import { IAsset, IAssetOverview } from './models/asset.model';
import { IUser } from './models/authentication.model';
import { IOfficeInfo } from './models/office.model';
import { IParams } from './shared-interfaces';

export const createIndexes = <T, G extends IParams>(data: T[], filter: G) => {
  const { page, size } = filter;
  return data.map((element, index) => ({
    ...element,
    index: page * size + index + 1,
  }));
};

const agencyNotificationRoute: Partial<INotificationRoute> = {
  [Notifications.CHECKING]: '/cms/ag_cchecking',
  [Notifications.ORDER]: '/cms/investor_orders',
  [Notifications.UPGRADE]: 'cms/agency_profile',
  [Notifications.UPGRADE_EXPERT]: 'cms/agency_profile',
  [Notifications.UPGRADE_OFFICE]: 'cms/agency_profile',
  [Notifications.UPGRADE_TRADE]: 'cms/agency_profile',
};
const backendNotificationRoute: Partial<INotificationRoute> = {
  [Notifications.CHECKING]: '/cms/head_cchecking',
  [Notifications.UPGRADE_EXPERT]: '/cms/expert_approval',
  [Notifications.UPGRADE_OFFICE]: '/cms/office_approval',
  [Notifications.UPGRADE_TRADE]: '/cms/trade_office_approval',
};
const masterNotificationRoute: Partial<INotificationRoute> = {
  [Notifications.CHECKING]: '/cms/ma_cchecking',
};

export const notificationRouteMapping: INotificationMapping = {
  [Roles.AGENCY]: agencyNotificationRoute,
  [Roles.TRADE]: agencyNotificationRoute,
  [Roles.EXPERT]: agencyNotificationRoute,
  [Roles.OFFICE]: agencyNotificationRoute,
  [Roles.EXPERT_OFFICE]: agencyNotificationRoute,
  [Roles.EXPERT_TRADE]: agencyNotificationRoute,
  [Roles.OFFICE_TRADE]: agencyNotificationRoute,
  [Roles.EXPERT_OFFICE_TRADE]: agencyNotificationRoute,
  [Roles.BACKEND]: backendNotificationRoute,
  [Roles.ADMIN]: backendNotificationRoute,
  [Roles.MASTER_AGENCY]: masterNotificationRoute,
};

export const tempRoleMaping = (tempRole: Roles) => {
  switch (tempRole) {
    case Roles.AGENCY_INVESTOR:
      return Roles.AGENCY;
    case Roles.EXPERT_INVESTOR:
      return Roles.EXPERT;
    case Roles.TRADE_INVESTOR:
      return Roles.TRADE;
    case Roles.EXPERT_TRADE_INVESTOR:
      return Roles.EXPERT_TRADE;
    case Roles.OFFICE_TRADE_INVESTOR:
      return Roles.OFFICE_TRADE;
    case Roles.EXPERT_OFFICE_TRADE_INVESTOR:
      return Roles.EXPERT_OFFICE_TRADE;
    case Roles.OFFICE_INVESTOR:
      return Roles.OFFICE;
    case Roles.MASTER_INVESTOR:
      return Roles.MASTER_AGENCY;
    case Roles.EXPERT_OFFICE_INVESTOR:
      return Roles.EXPERT_OFFICE;
    default:
      return tempRole;
  }
};

export const rolesMappingTempRole = (roles: Roles) => {
  switch (roles) {
    case Roles.AGENCY:
      return Roles.AGENCY_INVESTOR;
    case Roles.EXPERT:
      return Roles.EXPERT_INVESTOR;
    case Roles.OFFICE:
      return Roles.OFFICE_INVESTOR;
    case Roles.TRADE:
      return Roles.TRADE_INVESTOR;
    case Roles.EXPERT_TRADE:
      return Roles.EXPERT_OFFICE_TRADE_INVESTOR;
    case Roles.OFFICE_TRADE:
      return Roles.OFFICE_TRADE_INVESTOR;
    case Roles.EXPERT_OFFICE_TRADE:
      return Roles.EXPERT_OFFICE_TRADE_INVESTOR;
    case Roles.MASTER_AGENCY:
      return Roles.MASTER_INVESTOR;
    case Roles.EXPERT_OFFICE:
      return Roles.EXPERT_OFFICE_INVESTOR;
    default:
      return roles;
  }
};

export const defaulTypeChange = (type: Roles) => {
  switch (type) {
    case Roles.ADMIN:
    case Roles.BACKEND:
      return Roles.ADMIN;
    case Roles.MASTER_AGENCY:
    case Roles.MASTER_INVESTOR:
      return Roles.MASTER_AGENCY;
    case Roles.AGENCY:
    case Roles.EXPERT:
    case Roles.OFFICE:
    case Roles.AGENCY_INVESTOR:
    case Roles.EXPERT_INVESTOR:
    case Roles.OFFICE_INVESTOR:
    case Roles.EXPERT_OFFICE:
    case Roles.EXPERT_OFFICE_INVESTOR:
    case Roles.TRADE:
    case Roles.TRADE_INVESTOR:
    case Roles.EXPERT_TRADE:
    case Roles.EXPERT_TRADE_INVESTOR:
    case Roles.OFFICE_TRADE:
    case Roles.OFFICE_TRADE_INVESTOR:
    case Roles.EXPERT_OFFICE_TRADE:
    case Roles.EXPERT_OFFICE_TRADE_INVESTOR:
      return Roles.AGENCY;
    default:
      return Roles.INVESTOR;
  }
};

export const noMoreThanOneCommas = (input: number | string) => {
  const str = input.toString();
  let commasCount = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '.') commasCount++;
    if (commasCount > 1) break;
  }
  return commasCount <= 1;
};

export const insertCommas = (input: number | undefined | string) => {
  if (typeof input !== 'undefined') {
    if (!noMoreThanOneCommas(input)) return '';
    const parts = input.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (parts[1]) parts[1] = parts[1].substring(0, 4); // Only take the first 4 decimals
    return parts.join('.');
  } else {
    return '';
  }
};

export const unInsertCommas = (input: string) => {
  const parts = input.split('.');
  parts[0] = parts[0].replaceAll(',', '');
  if (parts[1]) parts[1] = parts[1].substring(0, 4); // Only take the first 4 decimals
  return parts.join('.');
};

export const firstDayOfMonth = () => {
  const curr = new Date();
  curr.setDate(curr.getDate() + 1);
  const startingDayOfTheMonth = dayjs(new Date(curr.getFullYear(), curr.getMonth(), 1)).format('YYYY-MM-DD');
  return startingDayOfTheMonth;
};

export const lastDayOfMonth = () => {
  const curr = new Date();
  curr.setDate(curr.getDate() + 1);
  const finalDayOfTheMonth = dayjs(new Date(curr.getFullYear(), curr.getMonth() + 1, 0)).format('YYYY-MM-DD');
  return finalDayOfTheMonth;
};

export const dateIsSame = (input: string | undefined, compared: string | undefined) => {
  return dayjs(dayjs(input).format(HTML_DATE_TIME_INPUT_FORMAT)).isSame(
    dayjs(compared).format(HTML_DATE_TIME_INPUT_FORMAT)
  );
};

export const dateAfterOrSame = (input: string | undefined, compared: string | undefined) => {
  return (
    dateIsSame(input, compared) ||
    dayjs(dayjs(input).format(HTML_DATE_TIME_INPUT_FORMAT)).isAfter(dayjs(compared).format(HTML_DATE_TIME_INPUT_FORMAT))
  );
};

export const isDateAfter = (input: string | undefined, compared: string | undefined) => {
  return dayjs(dayjs(input).format(HTML_DATE_TIME_INPUT_FORMAT)).isAfter(
    dayjs(compared).format(HTML_DATE_TIME_INPUT_FORMAT)
  );
};

export const datebeforeOrSame = (input: string | undefined, compared: string | undefined) => {
  return (
    dateIsSame(input, compared) ||
    dayjs(dayjs(input).format(HTML_DATE_TIME_INPUT_FORMAT)).isBefore(
      dayjs(compared).format(HTML_DATE_TIME_INPUT_FORMAT)
    )
  );
};

export const isDateBefore = (input: string | undefined, compared: string | undefined) => {
  return dayjs(dayjs(input).format(HTML_DATE_TIME_INPUT_FORMAT)).isBefore(
    dayjs(compared).format(HTML_DATE_TIME_INPUT_FORMAT)
  );
};

export function dateRangeOverlaps(a_start: number, a_end: number, b_start: number, b_end: number) {
  if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
  if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
  if (b_start < a_start && a_end < b_end) return true; // a in b
  return false;
}

export const filterDisabledEntities = <T extends { status: Status }>(entities: T[]): T[] => {
  return entities.filter((e) => e.status !== Status.DISABLE);
};

export const formatLocalDate = (input: string | Date | undefined): string => {
  return dayjs(input).format(APP_LOCAL_DATE_FORMAT);
};

export const formatLocalDatetime = (input: string | Date | undefined): string => {
  return dayjs(input).format(APP_DATE_FORMAT);
};

export const formatLocalDateTime = (input: string | Date | undefined): string => {
  return dayjs(input).format(APP_DATE_FORMAT);
};

export const exchangeUsd = (side: Side, amount: number = 0, countryRate: number, usdRate: number) => {
  let output = 0;

  if (side === Side.BUY) {
    const countryAmount = amount / countryRate;
    output = countryAmount * usdRate;
  } else if (side === Side.SELL) {
    const countryAmount = amount / usdRate;
    output = countryAmount * countryRate;
  }
  return output.toFixed(4).toString();
};

export const returnPercentageOfX = (x: number, percentage: number) => {
  return ((percentage * x) / 100).toFixed(4);
};

export const xIsWhatPercentOfY = (x: number, y: number) => {
  if (y === 0) return '0.00';
  return ((x / y) * 100).toFixed(2);
};

export const returnIndexPagination = (i: number, itemsPerPage: number, activePage: number) => {
  return itemsPerPage * activePage + i + 1;
};

export const returnOrganizationName = (entity: string | IUser | undefined | IAccount) => {
  if (typeof entity === 'string' || !entity) return '_';
  return entity.agencyName || entity.fullName || `${entity.lastName} ${entity.firstName}` || '_';
};

export const returnTokenAmount = (input: null | undefined | string | number): string => {
  if (typeof input !== 'string' && typeof input !== 'number') return '_';
  return `${insertCommas(Number(input))} ${TOKEN_NAME}`;
};

export const netTokenAG = (item: IAsset): number => {
  const { agencyBonus, masterBonusAgency, parentCashback, childrenCashback, agencyBonusInvestor } = item;
  return agencyBonus + masterBonusAgency + childrenCashback - parentCashback - agencyBonusInvestor;
};

export const totalNetTokenMA = (item: IAssetOverview): number => {
  const { totalMasterBonus, companyToAgency, masterToAgency } = item;
  return totalMasterBonus - companyToAgency - masterToAgency;
};

export const netTokenMA = (item: IAsset): number => {
  const { masterBonus, agencyBonus, masterBonusAgency } = item;
  return masterBonus - agencyBonus - masterBonusAgency;
};

export const detailAddress = (item: IOfficeInfo): string => {
  return `${item.absoluteAddress}, ${item.ward.name}, ${item.district.name}, ${item.province.name}` || '_';
};

export default function useDebounce(value: string | undefined, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return debouncedValue;
}

export const returnFileExtension = (srcFile: string) => {
  return srcFile.slice(((srcFile.lastIndexOf('.') - 1) >>> 0) + 2);
};

export const imageExtension = ['jpeg', 'jpg', 'png', 'webp', 'bitmap'];
export const videoExtension = ['mp4', 'm4a', 'webm'];

export const getEllipsisTxt = (str: string, n = 5) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return '';
};
