import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { pickBy } from 'lodash';
import axios from '../../../config/axios-interceptor';
import axiosKeyCloak from '../../../config/axios-interceptor-keycloak';
import { Language } from '../../../enumeration/Language';
import { Roles } from '../../../enumeration/roles';
import { Status } from '../../../enumeration/status';
import { tempRoleMaping } from '../../../shared/helper';
import { IUser } from '../../../shared/models/authentication.model';
import { INewTradeOffice, ITradeOffice } from '../../../shared/models/tradeOffice.model';
import { IWithdrawTransaction } from '../../../shared/models/withdrawTransaction.model';
import { IParams } from '../../../shared/shared-interfaces';
import { IAuthenticateOTP } from '../../authentication/auth.api';
import { IRegisterRealEstateMgmtBody } from './RealEstateMgmtRegistrationModal';

export interface IOrdersBody {
  paymentType: string;
  cash: string;
  amount: string;
  wallet: string;
}

export interface IAvatar {
  url: string;
}

export interface IUpdateBody {
  body: {
    imageUrl: string;
    lastName?: string;
    firstName?: string;
    fullName?: string;
    phone: string;
    email: string;
    idCard: string;
    blockchainAddress?: string;
    otpCurrentEmail?: number;
    otpNewEmail?: number;
    otpCurrentPhone?: number;
    otpNewPhone?: number;
  };
  avatar: any;
  currUser: IUser;
  password: string;
}

interface IUpdateLanguageBody {
  langKey: Language;
}

interface IChangePasswordBody {
  currentUserPassword: string;
  newPassword: string;
  otp: string;
}

export interface ITransferAnftBody {
  otp: string;
  amount: string;
  receiver: {
    id: string;
  };
}

export const anftOrder = createAsyncThunk('anftOrder', async (body: IOrdersBody, thunkAPI) => {
  try {
    const result = await axios.post(`orders`, body);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const changePassword = createAsyncThunk('changePassword', async (body: IChangePasswordBody, thunkAPI) => {
  try {
    const result = await axios.post(`account/change-password`, body);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const transferAnft = createAsyncThunk('transferAnft', async (body: ITransferAnftBody, thunkAPI) => {
  try {
    const result = await axios.post(`transactions`, body);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const transferAnftHistory = createAsyncThunk('transferAnftHistory', async (params: IParams, thunkAPI) => {
  try {
    const result = await axios.get(`transactions`, { params });
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const userAffiliate = createAsyncThunk('userAffiliate', async (_, thunkAPI) => {
  try {
    const result = await axios.get(`users/sub-agency`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getUserByPhone = createAsyncThunk('getUserByPhone', async (phone: string, thunkAPI) => {
  try {
    const result = await axios.get(`users/phone/${phone}`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const uploadAvatar = async (avatar: any): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append('file', avatar);
    const { data }: AxiosResponse<IAvatar[]> = await axios.post(`avatar`, formData);
    return data[0].url;
  } catch (error: any) {
    return error;
  }
};

export const accountUpdate = createAsyncThunk('accountUpdate', async (updateBody: IUpdateBody, thunkAPI) => {
  try {
    const { avatar, currUser, body, password } = updateBody;
    const avatarUrl = avatar ? await uploadAvatar(avatar) : undefined;
    const requestBody = {
      ...currUser,
      ...body,
      imageUrl: avatarUrl,
      type: currUser.type === Roles.INVESTOR ? currUser.type : tempRoleMaping(currUser.type),
      password: password,
    };
    const { data } = await axios.post(`account`, pickBy(requestBody));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const changeLanguage = createAsyncThunk(
  'changeLanguage',
  async (updateLanguageBody: IUpdateLanguageBody, thunkAPI) => {
    try {
      const result = await axios.post(`account/lang`, updateLanguageBody);
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDiscountPolicy = createAsyncThunk('getDiscountPolicy', async (_, thunkAPI) => {
  try {
    const result = await axios.get(`rewards/agency/investor`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCurrentStages = createAsyncThunk('getCurrentStages', async (_, thunkAPI) => {
  try {
    const result = await axios.get(`stages/current-period`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getUserCurrentAnft = createAsyncThunk('getUserCurrentAnft', async (_, thunkAPI) => {
  try {
    const result = await axios.get(`/user-tokens`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getOrderHistory = createAsyncThunk('getUserOrderHistory', async (params: IParams, thunkAPI) => {
  try {
    const result = await axios.get(`orders/histories`, { params });
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const transactionsCheck = createAsyncThunk('transactionsCheck', async (_, thunkAPI) => {
  try {
    const result = await axios.get(`transactions/check`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const ordersCheck = createAsyncThunk('ordersCheck', async (_, thunkAPI) => {
  try {
    const result = await axios.get(`orders/check`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAccountInfo = createAsyncThunk('getAccountInfo', async (_, thunkAPI) => {
  try {
    const result = await axios.get(`account`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const withdrawAnft = createAsyncThunk(
  'withdrawAnft',
  async (body: { address: string; amount: string }, thunkAPI) => {
    try {
      const result = await axios.post(`chain-transactions`, body);
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getWithdrawAnftHistory = createAsyncThunk('getWithdrawAnftHistory', async (params: IParams, thunkAPI) => {
  try {
    const result = await axios.get<IWithdrawTransaction[]>(`chain-transactions`, { params });
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const withdrawAuthenticate = createAsyncThunk(
  'withdrawAuthenticate',
  async (body: IAuthenticateOTP, thunkAPI) => {
    try {
      const result = await axios.post(`chain-transactions/verify`, body);
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface IGetRegistrationConfig {
  parentId: string;
  stageId: string;
}

export const getRealEstateMgmtRegistrationConfig = createAsyncThunk(
  'get-realestate-mgmt-registration-config',
  async (params: IGetRegistrationConfig, thunkAPI) => {
    try {
      const result = await axiosKeyCloak.get(`registration-configs`, { params });
      return result;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const registerRealEstateMgmt = createAsyncThunk(
  'register-real-estate-managament',
  async (body: IRegisterRealEstateMgmtBody, thunkAPI) => {
    try {
      const result = await axiosKeyCloak.post(`registrations`, body);
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const realEstateMgmtAccount = createAsyncThunk(`account`, async (_, thunkAPI) => {
  try {
    const { data } = await axiosKeyCloak.get(`account`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IListingType {
  id: string;
  name: string;
}

interface IListingTypesFilterParams extends IParams {
  status?: Status;
}

export const getListingTypesRealEstateMgmt = createAsyncThunk(
  `get-all-listing-types`,
  async (fields: IListingTypesFilterParams | undefined, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axiosKeyCloak.get<IListingType[]>(`listing-types`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const registerTradeOffice = createAsyncThunk(
  `register-trade-office`,
  async (body: INewTradeOffice, thunkAPI) => {
    try {
      const { logo } = body;
      const logoUrl = logo ? await uploadAvatar(logo) : undefined;
      const requestBody = {
        ...body,
        logo: logoUrl,
      };

      const { data } = await axios.post(`office-registrations`, pickBy(requestBody));
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getTradeOfficeInfo = createAsyncThunk(`get-trade-office-info`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ITradeOffice>(`office-registrations/${id}/my-registrations`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
