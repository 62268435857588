import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { getEntities, createEntity, updateEntity, claimReward } from './cchecking.api';

import { AxiosResponse } from 'axios';
import { ICCheck } from '../../../../shared/models/ccheck.model';
import { RootState } from '../../../../shared/reducers';
import { IInitialState } from '../../../../shared/shared-interfaces';



const initialState: IInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const CCAdapter = createEntityAdapter<ICCheck>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'ccSlice',
  initialState: CCAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    setAll: CCAdapter.setAll,
    updateOne: CCAdapter.updateOne,
    addOne: CCAdapter.addOne,
    removeOne: CCAdapter.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<any>>) => {
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },

    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<ICCheck>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
    },
    [claimReward.fulfilled.type]: (state, { payload }: PayloadAction<ICCheck>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [claimReward.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<ICCheck>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
    },

  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne, addOne, removeOne } = actions;

export const ccSelectors = CCAdapter.getSelectors<RootState>((state) => state.cchecking);

const { selectById } = CCAdapter.getSelectors();
const getCCheckState = (rootState: RootState) => rootState.cchecking;

export const selectEntityById = (id: string | undefined) => {
  return createSelector(getCCheckState, (state) => {
    if (!id) return undefined;
    return selectById(state, id)
  });
};

