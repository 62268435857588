import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { Roles } from '../../../../enumeration/roles';
import { ICondition, ICurrentCondition, INewCondition } from '../../../../shared/models/condition.model';
import { IConditionFilter } from './accountUpgrade/ConditionManagement';
import { addOne, removeOne, setAll, updateOne } from './condition.reducer';

const prefix = 'conditions';

// export const getEntities = createAsyncThunk('get-groups', async (params: IGroupFilterState, thunkAPI) => {
export const getEntities = createAsyncThunk('admin-get-conditions', async (field: IConditionFilter, thunkAPI) => {
  try {
    const params = _.pickBy(field);
    const result = await axios.get<ICondition[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk('admin-get-condition', async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<ICondition>(`${prefix}/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk('admin-update-condition', async (body: ICondition, thunkAPI) => {
  try {
    const { id } = body;
    const result = await axios.put(`${prefix}/${id}`, body);
    // const result = await axios.put(`${prefix}`, body);
    if (id) {
      thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    }
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk('admin-create-condition', async (body: INewCondition, thunkAPI) => {
  try {
    const result = await axios.post(`${prefix}`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const removeEntity = createAsyncThunk('admin-delete-condition', async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCurrentCondition = createAsyncThunk(
  'get-current-conditions',
  async (field: { type: string }, thunkAPI) => {
    try {
      const params = _.pickBy(field);
      const { data } = await axios.get<ICondition>(`${prefix}`, { params });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCurrentStageCondition = createAsyncThunk('get-current-stage-conditions', async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<ICurrentCondition[]>(`${prefix}/current`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IGetStgCondBody {
  stageId: string,
  type: Roles
}

export const getStageConditions = createAsyncThunk('get-stage-conditions', async (params: IGetStgCondBody, thunkAPI) => {
  try {
    const { data } = await axios.get<ICondition[]>(`${prefix}`, { params });
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
