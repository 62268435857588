import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from '../../../../../../config/axios-interceptor';
import { INewTradeCondition, ITradeCondition } from '../../../../../../shared/models/condition.model';
import { IConditionFilter } from '../../accountUpgrade/ConditionManagement';
import { addOne, removeOne, setAll, updateOne } from './tradeCondition.reducer';

const prefix = 'office-conditions';

// export const getEntities = createAsyncThunk('get-groups', async (params: IGroupFilterState, thunkAPI) => {
export const getEntities = createAsyncThunk(`admin-get-${prefix}`, async (field: IConditionFilter, thunkAPI) => {
  try {
    const params = _.pickBy(field);
    const result = await axios.get<ITradeCondition[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`admin-get-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<ITradeCondition>(`${prefix}/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(
  `admin-update-${prefix}`,
  async (body: ITradeCondition, thunkAPI) => {
    try {
      const { id } = body;
      const result = await axios.put(`${prefix}/${id}`, body);
      // const result = await axios.put(`${prefix}`, body);
      if (id) {
        thunkAPI.dispatch(updateOne({ id, changes: result.data }));
      }
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createEntity = createAsyncThunk(
  `admin-crate-${prefix}`,
  async (body: INewTradeCondition, thunkAPI) => {
    try {
      const result = await axios.post(`${prefix}`, body);
      const { id } = result.data;
      thunkAPI.dispatch(addOne({ id, ...result.data }));
      return result.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const removeEntity = createAsyncThunk(`admin-delete-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
