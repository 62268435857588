import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../../config/axios-interceptor';
import { IExpertOfficeStats } from "../../../../shared/models/statisticStats.model";

const prefix = 'experts/growth';

export const getExpertStatistics = createAsyncThunk(`get-${prefix}`, async (_, thunkAPI) => {
  try {
    const {data} = await axios.get<IExpertOfficeStats>(`${prefix}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});