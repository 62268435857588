import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { Roles } from '../../../../enumeration/roles';
import { IAdminPolicy } from '../../../../shared/models/admin-policy.model';
import { ICondition } from '../../../../shared/models/condition.model';
import { IMasterAgencyPolicy } from '../../../../shared/models/masterAgencyPolicy.model';
import { IGetCrrntPlcyParams } from './ManagementPolicy';

const company = 'policies/current-policy';
const master = 'rewards/parent-reward';
const current = 'conditions/current';

export const getMasterPolicy = createAsyncThunk(`get-${master}`, async (_, thunkAPI) => {
  try {
    const result = await axios.get<IMasterAgencyPolicy>(`${master}`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCompanyPolicy = createAsyncThunk(`get-${company}`, async (type: Roles, thunkAPI) => {
  try {
    const result = await axios.get<IAdminPolicy>(`${company}`, { params: { type } });
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getCurrentCondition = createAsyncThunk(`get-${current}`, async (fields: IGetCrrntPlcyParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    const {data} = await axios.get<ICondition[]>(`${current}`, {params});
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
