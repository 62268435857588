/* eslint-disable react-hooks/exhaustive-deps */
import { getMessaging, getToken, isSupported, MessagePayload, Messaging, onMessage } from 'firebase/messaging';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Roles } from './enumeration/roles';
import firebaseApp from './firebase/firebase';
import './scss/style.scss';
import { ToastNotification } from './shared/components/Toast';
import { defaulTypeChange } from './shared/helper';
import { IUser } from './shared/models/authentication.model';
import { RootState } from './shared/reducers';
import { authenticate, loginKeyCloak, sendFirebaseToken } from './views/authentication/auth.api';
import { changeTempRoles, changeTypeUser, fetching } from './views/authentication/auth.reducer';
import { getEntities } from './views/backOffice/shared/NotificationManagement/notification.api';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheFrontOfficeLayout = React.lazy(() => import('./containers/frontOffice/TheLayout'));
const TheBackOfficeLayout = React.lazy(() => import('./containers/backOffice/TheLayout'));
// const Unauthorized = React.lazy(() => import("./views/errors/Unauthorized"));
const TheAuthLayout = React.lazy(() => import('./views/authentication/TheAuthLayout'));

const App = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [getMess, setGetMess] = useState<Messaging | null>(null);
  const { user, token, userLoginInfo } = useSelector((state: RootState) => state.authentication);

  const checkSupported = async () => {
    try {
      const bool = await isSupported();
      return await bool;
    } catch (err) {
      return false;
    }
  };

  const fireBaseGetMesasing = async () => {
    const check = await checkSupported();
    if (check) {
      try {
        const result = getMessaging(firebaseApp);
        return result;
      } catch (err) {
        return null;
      }
    }
  };

  const getFirebaseToken = async () => {
    const getFirebaseMessasing = await fireBaseGetMesasing();
    if (getFirebaseMessasing) {
      setGetMess(getFirebaseMessasing);
      try {
        await Notification.requestPermission();
        const token = await getToken(getFirebaseMessasing);
        dispatch(sendFirebaseToken({ token }));
      } catch (error) {
        console.log(`Error in getting firebase token: ${error}`);
      }
    }
  };

  if (getMess) {
    onMessage(getMess, (payload: MessagePayload) => {
      if (payload) {
        const title = t(
          `anftApp.global.notification.${payload.data!.translateType}.${defaulTypeChange(user!.type)}.title`,
          {
            name: payload.data!.actionTaker || '',
            value: payload.data!.value || '',
          }
        );
        const body = t(
          `anftApp.global.notification.${payload.data!.translateType}.${defaulTypeChange(user!.type)}.body`,
          {
            name: payload.data!.actionTaker || '',
            value: payload.data!.value || '',
          }
        );
        ToastNotification(title, body);
        dispatch(getEntities({ page: 0, size: 20 }));
      }
    });
  }

  useEffect(() => {
    const authToken =
      localStorage.getItem('authentication_token') || sessionStorage.getItem('authentication_token') || token;
    if (user && authToken) {
      if (user.langKey) {
        i18n.changeLanguage(user.langKey);
      }
      getFirebaseToken();
    }
  }, [user, token]);

  useEffect(() => {
    // if (user && ableRegisterREMRolesArray.includes(user.type) && userLoginInfo) {
    if (user && userLoginInfo) {
      dispatch(loginKeyCloak(userLoginInfo));
    }
  }, [user]);

  // useEffect(() => {
  //   if (errorMessage) {
  //     ToastError(t(`anftApp.global.errorsText.${errorMessage}`));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [errorMessage]);

  useEffect(() => {
    let tempToken = token;
    if (!tempToken) {
      tempToken = localStorage.getItem('authentication_token');
    }

    if (tempToken) {
      dispatch(fetching());
      dispatch(authenticate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const redirectUserByType = () => {
    if (user && user.activated) {
      const localRoles = localStorage.getItem('tempRole');
      switch (user.type) {
        case Roles.ADMIN:
        case Roles.BACKEND:
          return <Route path="/" component={TheBackOfficeLayout} />;
        case Roles.AGENCY:
        case Roles.TRADE:
        case Roles.EXPERT_TRADE:
        case Roles.OFFICE_TRADE:
        case Roles.EXPERT_OFFICE_TRADE:
        case Roles.EXPERT:
        case Roles.OFFICE:
        case Roles.MASTER_AGENCY:
        case Roles.EXPERT_OFFICE:
          if (localRoles) {
            const changeRoles: Roles = localRoles as Roles;
            dispatch(changeTempRoles(user!.type));
            const newUser: IUser = { ...user!, type: changeRoles };
            dispatch(changeTypeUser(newUser));
            localStorage.setItem('tempRole', changeRoles);
            return <Route path="/" component={TheFrontOfficeLayout} />;
          } else {
            return <Route path="/" component={TheBackOfficeLayout} />;
          }

        default:
          return <Route path="/" component={TheFrontOfficeLayout} />;
      }
    } else {
      return <Route path="/" component={TheAuthLayout} />;
    }
  };

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>{redirectUserByType()}</Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
