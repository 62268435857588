import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { IExpert, IExpertUpdate, INewExpert } from '../../../../shared/models/expert.model';
import { IExpertFilter } from '../../Admin/approvalManagement/expertApproval/ExpertApproval';

const prefix = 'experts';
const prefixUpdate = "experts/update"
const prefixEntity = 'experts/info';
const prefixHistory = 'experts/histories';

export const getEntities = createAsyncThunk(`get-${prefix}`, async (fields: IExpertFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IExpert[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const publicEntity = createAsyncThunk(`public-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } =  await axios.put<IExpert>(`${prefix}/${id}/public`);
    return data
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntitiesHistory = createAsyncThunk(`get-${prefixHistory}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IExpert[]>(`${prefixHistory}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-${prefixEntity}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IExpert>(`${prefixEntity}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntities = createAsyncThunk(`update-${prefixUpdate}`, async (body: IExpert, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefixUpdate}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntities = createAsyncThunk(`create-${prefix}`, async (body: INewExpert, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const approveExpert = createAsyncThunk(`approve-${prefix}`, async (body: IExpertUpdate, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}/approve`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
