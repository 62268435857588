import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdminPolicy } from '../../../../shared/models/admin-policy.model';
import { ICondition } from '../../../../shared/models/condition.model';
import { IMasterAgencyPolicy } from '../../../../shared/models/masterAgencyPolicy.model';
import { getMasterPolicy, getCompanyPolicy, getCurrentCondition } from './managementPolicy.api';

interface IManagementPolicyState {
  masterPolicy: null | IMasterAgencyPolicy;
  companyPolicy: null | IAdminPolicy;
  currentCondition: ICondition[];
  fetchEntitiesSuccess: boolean;
  loading: boolean;
  errorMessage: null | string;
}

const initialState: IManagementPolicyState = {
  masterPolicy: null,
  currentCondition: [],
  companyPolicy: null,
  fetchEntitiesSuccess: false,
  loading: false,
  errorMessage: null,
};

const { reducer, actions } = createSlice({
  name: 'agencyManagementPolicySlice',
  initialState,
  reducers: {
    fetching: (state) => {
      state.loading = true;
    },
    resetEntity: (state) => {
      state.loading = false;
      state.errorMessage = null;
    },
    resetAll: (state) => {
      state = initialState;
    },
  },
  extraReducers: {
    [getMasterPolicy.fulfilled.type]: (state, { payload }: PayloadAction<IMasterAgencyPolicy>) => {
      state.fetchEntitiesSuccess = true;
      state.loading = false;
      state.masterPolicy = payload;
    },
    [getMasterPolicy.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.fetchEntitiesSuccess = false;
    },
    [getCompanyPolicy.fulfilled.type]: (state, { payload }: PayloadAction<IAdminPolicy>) => {
      state.fetchEntitiesSuccess = true;
      state.loading = false;
      state.companyPolicy = payload;
    },
    [getCompanyPolicy.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.fetchEntitiesSuccess = false;
    },
    [getCurrentCondition.fulfilled.type]: (state, { payload }: PayloadAction<ICondition[]>) => {
      state.currentCondition = payload;
      state.fetchEntitiesSuccess = true;
      state.loading = false;
    },
    [getCurrentCondition.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.fetchEntitiesSuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetEntity, resetAll } = actions;
