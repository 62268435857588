import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../config/axios-interceptor';
import { INewUser, IUser } from '../../../../shared/models/authentication.model';
import { IExpertFilter } from '../../Admin/approvalManagement/expertApproval/ExpertApproval';
import { removeOne, setAll, updateOne } from './expertManagement.reducer';

const prefix = '/users/expert'; 

export const getEntities = createAsyncThunk('get-experts', async (params: IExpertFilter, thunkAPI) => {
  try {
    //   const result = await axios.get(`${prefix}`, { params });
    const result = await axios.get<IUser[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk('get-expert', async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IUser>(`users/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk('update-expert', async (body: IUser | INewUser, thunkAPI) => {
  try {
    const { id } = body;
    // const result = await axios.put(`${prefix}/${id}`, body);
    const result = await axios.put(`${prefix}`, body);
    if (id) {
      thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    }
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk('delete-expert', async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
