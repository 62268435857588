import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { IUser } from '../../../../shared/models/authentication.model';
import { IEmail, INewEmail } from '../../../../shared/models/email.model';
import { IEmailsFilter } from './Emails';

const prefix = 'emails';
const getUsersPrefix = 'users';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IEmailsFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IEmail[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IEmail>(`${prefix}/${id}`);
    return [data];
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IEmail, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IEmail>(`${prefix}/${id}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewEmail, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getUsers = createAsyncThunk(`get-all-${getUsersPrefix}`, async (keyword: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IUser[]>(`${getUsersPrefix}`, { params: { keyword } });
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
