import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import { IExpertBlog, INewExpertBlog } from '../../../../shared/models/expertBlog.model';
import { IAvatar } from '../../../frontOffice/dashboard/dashboard.api';
import { IExpertBlogsFilter } from './ExpertBlogManagement';

const prefix = 'blogs';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IExpertBlogsFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IExpertBlog[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IExpertBlog>(`${prefix}/${id}`);
    return [data];
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const uploadImage = async (image: any): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append('file', image);
    const { data }: AxiosResponse<IAvatar[]> = await axios.post(`avatar`, formData);
    return data[0].url;
  } catch (error: any) {
    return error;
  }
};

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IExpertBlog, thunkAPI) => {
  try {
    const { id, image } = body;
    const imageBlogUrl = image ? await uploadImage(image) : undefined;
    delete body.imageUrl;
    const requestBody = {
      ...body,
      image: imageBlogUrl,
    };
    const { data } = await axios.put<IExpertBlog>(`${prefix}/${id}`, requestBody);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewExpertBlog, thunkAPI) => {
  try {
    delete body.createdDate;
    const { image } = body;
    const imageBlogUrl = image ? await uploadImage(image) : undefined;
    delete body.imageUrl;
    const requestBody = {
      ...body,
      image: imageBlogUrl,
    };
    const { data } = await axios.post(`${prefix}`, pickBy(requestBody));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
