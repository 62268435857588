export enum Status {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}

export const statusArray: Status[] = [Status.ENABLE, Status.DISABLE];

export const mapStatus: { [key in Status]: string } = {
  [Status.ENABLE]: 'HOẠT ĐỘNG',
  [Status.DISABLE]: 'KHÔNG HOẠT ĐỘNG',
};

export const mapStatusBadge: { [key in Status]: string } = {
  [Status.ENABLE]: 'success',
  [Status.DISABLE]: 'danger',
};
