import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../config/axios-interceptor';
import {
  INewTrainingCourse,
  ITrainingCourse,
  ITrainingCourseRegistered,
} from '../../../../shared/models/trainingCourse.model';
import { IParams } from '../../../../shared/shared-interfaces';

const prefix = 'training-courses';

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<ITrainingCourse[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<ITrainingCourse>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: ITrainingCourse, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<ITrainingCourse>(`${prefix}/${id}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewTrainingCourse, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, body);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getRegisteredEntities = createAsyncThunk(
  `get-all-registered-${prefix}`,
  async (fields: IParams, thunkAPI) => {
    try {
      const params = pickBy(fields);
      return await axios.get<ITrainingCourseRegistered[]>(`${prefix}/registrant-history`, { params });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const registerEntity = createAsyncThunk(`register-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}/${id}/register`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const withdrawEntity = createAsyncThunk(`withdraw-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.post(`${prefix}/${id}/withdraw`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const payoutEntity = createAsyncThunk(`payout-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}/${id}/payout`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface ITrainingCoursesRevenue {
  totalOrganizerRevenue: number;
  totalSystemRevenue: number;
}

export const getEntitiesRevenue = createAsyncThunk(`get-revenue-${prefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<ITrainingCoursesRevenue>(`${prefix}/revenue`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
