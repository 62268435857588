export const checkIsLocalhost = (): boolean => {
  const isLocalHost =
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    Boolean(window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
  return isLocalHost;
};

export enum EnvEnum {
  DEV = 'development',
  PROD = 'production',
  LOCAL = 'local',
}

const devHost = 'dev.anfteco.vn';
const prodHost = 'sale.anfteco.vn';

const envDetect = (): EnvEnum => {
  const hostname = window.location.hostname;
  if (checkIsLocalhost()) {
    return EnvEnum.LOCAL;
  } else if (hostname === devHost) {
    return EnvEnum.DEV;
  } else if (hostname === prodHost) {
    return EnvEnum.PROD;
  }
  return EnvEnum.DEV;
};

export const _env = envDetect();
