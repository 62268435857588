import { Roles } from './roles';

export enum Notifications {
  ORDER = 'ORDER',
  TRANSACTION = 'TRANSACTION',
  CHECKING = 'CHECKING',
  UPGRADE = 'UPGRADE',
  UPGRADE_EXPERT = 'UPGRADE_EXPERT',
  UPGRADE_OFFICE = 'UPGRADE_OFFICE',
  UPGRADE_TRADE = 'UPGRADE_TRADE',
}

export enum NotificationsTranslateType {
  ORDER_CREATE = 'ORDER_CREATE',
  ORDER_APPROVE = 'ORDER_APPROVE',
  ORDER_FINISH = 'ORDER_FINISH',
  CHECKING_CREATE = 'CHECKING_CREATE',
  CHECKING_APPROVE = 'CHECKING_APPROVE',
  CHECKING_REJECTED = 'CHECKING_REJECTED',
  UPGRADE_CREATE_AGENCY = 'UPGRADE_CREATE_AGENCY',
  UPGRADE_CREATE_EXPERT = 'UPGRADE_CREATE_EXPERT',
  UPGRADE_CREATE_OFFICE = 'UPGRADE_CREATE_OFFICE',
  UPGRADE_CREATE_TRADE = 'UPGRADE_CREATE_TRADE',
  UPGRADE_APPROVE = 'UPGRADE_APPROVE',
  UPGRADE_REJECTED = 'UPGRADE_REJECTED',
  APPROVE_EXPERT = 'APPROVE_EXPERT',
  REJECT_EXPERT = 'REJECT_EXPERT',
  APPROVE_OFFICE = 'APPROVE_OFFICE',
  REJECT_OFFICE = 'REJECT_OFFICE',
  APPROVE_TRADE = 'APPROVE_TRADE',
  REJECT_TRADE = 'REJECT_TRADE',
}

export type RolesHaveNotification =
  | Roles.AGENCY
  | Roles.EXPERT
  | Roles.TRADE
  | Roles.EXPERT_TRADE
  | Roles.OFFICE_TRADE
  | Roles.EXPERT_OFFICE_TRADE
  | Roles.OFFICE
  | Roles.EXPERT_OFFICE
  | Roles.BACKEND
  | Roles.ADMIN
  | Roles.MASTER_AGENCY;
export type INotificationRoute = { [key in Notifications]: string };
export type INotificationMapping = { [key in RolesHaveNotification]: Partial<INotificationRoute> };
export type ISeeAllNotificationMappping = { [key in RolesHaveNotification]: string };
