import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAgencyMasterSocial, ISocialGroup } from '../../../../shared/models/socialGroup.model';
import { getAdminEntities, getAgencyMasterEntities, getEntities, updateEntities } from './socialGroup.api';

interface IInitialState {
  fetchAdminSuccess:boolean;
  fetchAgencyMasterSuccess:boolean;
  fetchSuccess: boolean;
  updateSuccess: boolean;
  errorMessage: string | null;
  loading: boolean;
  entities: ISocialGroup[];
  adminEntities:ISocialGroup[];
  agencyMasterEntities:IAgencyMasterSocial|null;
}

const initialState: IInitialState = {
  fetchAdminSuccess:false,
  fetchAgencyMasterSuccess:false,
  fetchSuccess: false,
  updateSuccess: false,
  loading: false,
  errorMessage: null,
  entities: [],
  adminEntities:[],
  agencyMasterEntities:null
};

const { reducer, actions } = createSlice({
  name: 'socialGroupSlice',
  initialState,
  reducers: {
    fetching: (state) => {
      state.loading = true;
    },
    resetEntity: (state) => {
      state.loading = false;
      state.errorMessage = null;
      state.updateSuccess = false;
      state.fetchAdminSuccess=false;
      state.fetchAgencyMasterSuccess = false;
    },
    resetAll: (state) => {
      state = initialState;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<ISocialGroup[]>) => {
      state.loading = false;
      state.entities = payload;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.fetchSuccess = false;
    },
    [updateEntities.fulfilled.type]: (state, { payload }: PayloadAction<ISocialGroup>) => {
      state.updateSuccess = true;
      state.loading = false;
    },
    [updateEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.updateSuccess = false;
    },
    [getAdminEntities.fulfilled.type]: (state, { payload }: PayloadAction<ISocialGroup[]>) => {
      state.loading = false;
      state.adminEntities = payload;
      state.fetchAdminSuccess = true;
    },
    [getAdminEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.fetchAdminSuccess = false;
    },
    [getAgencyMasterEntities.fulfilled.type]: (state, { payload }: PayloadAction<IAgencyMasterSocial>) => {
      state.loading = false;
      state.agencyMasterEntities = payload;
      state.fetchAgencyMasterSuccess = true;
    },
    [getAgencyMasterEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.fetchAgencyMasterSuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity } = actions;
