import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Roles } from '../../enumeration/roles';
import { IUser } from '../../shared/models/authentication.model';
import {
  accountUpdate,
  authenticate,
  createAccount,
  login,
  loginKeyCloak,
  logout,
  otpAuthenticate,
  register,
  resetAuthentication,
  sendOtpToCurrPhone,
  sendOtpToUpdatePhoneOrEmail,
  verifyZaloByNewPhone,
  verifyZaloOTP,
} from './auth.api';

interface LoginValues {
  username: string;
  password: string;
}
interface IAuthenticationState {
  updateSuccess: boolean;
  loginSuccess: boolean;
  registerSuccess: boolean;
  createAccountSuccess: boolean;
  getIdOtpSuccess: boolean;
  logoutSuccess: boolean;
  loading: boolean;
  fileUrl: any;
  tempRole: Roles;
  user: IUser | null;
  token: string | null;
  id_otp: string | null;
  errorMessage: string | null;
  verifyOtpSuccess: boolean | undefined;
  sendOtpToCurrAndNewPhoneOrEmailSuccess: boolean;
  sendOtpToCurrPhoneSuccess: boolean;
  userLoginInfo: LoginValues | null;
}

const initialState: IAuthenticationState = {
  updateSuccess: false,
  createAccountSuccess: false,
  loginSuccess: false,
  registerSuccess: false,
  getIdOtpSuccess: false,
  logoutSuccess: false,
  fileUrl: null,
  tempRole: Roles.AGENCY,
  loading: false,
  user: null,
  token: null,
  id_otp: null,
  errorMessage: null,
  verifyOtpSuccess: undefined,
  sendOtpToCurrAndNewPhoneOrEmailSuccess: false,
  sendOtpToCurrPhoneSuccess: false,
  userLoginInfo: null,
};

// export type IAuthentication = Readonly<typeof initialState>;

const authenticationSlice = createSlice({
  name: 'authenticationSlice',
  initialState,
  reducers: {
    changeTypeUser: (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
    },
    changeTempRoles: (state, { payload }: PayloadAction<Roles>) => {
      state.tempRole = payload;
    },
    storeTokenInStore: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
    },
    storeUserInfo: (state, { payload }: PayloadAction<LoginValues>) => {
      state.userLoginInfo = payload;
    },
    fetching(state) {
      state.loading = true;
    },
    reset(state) {
      state.userLoginInfo = null;
      state.loading = false;
      state.createAccountSuccess = false;
      state.loginSuccess = false;
      state.registerSuccess = false;
      state.logoutSuccess = false;
      state.getIdOtpSuccess = false;
      state.updateSuccess = false;
      state.fileUrl = null;
      state.user = null;
      state.token = null;
      state.id_otp = null;
      state.errorMessage = null;
      localStorage.removeItem('authentication_token');
      localStorage.removeItem('id_otp');
      localStorage.removeItem('tempRole');
      localStorage.removeItem('keycloak_authentication_token');
    },
    softReset(state) {
      state.loading = false;
      state.updateSuccess = false;
      state.createAccountSuccess = false;
      state.loginSuccess = false;
      state.registerSuccess = false;
      state.logoutSuccess = false;
      state.getIdOtpSuccess = false;
      state.errorMessage = null;
    },
    otpReset(state) {
      state.verifyOtpSuccess = undefined;
      state.sendOtpToCurrAndNewPhoneOrEmailSuccess = false;
      state.sendOtpToCurrPhoneSuccess = false;
      state.errorMessage = null;
    },
  },
  extraReducers: {
    [login.fulfilled.type]: (state, { payload }: PayloadAction<{ id_token: string }>) => {
      state.loginSuccess = true;
      localStorage.setItem('authentication_token', payload.id_token);
      state.token = payload.id_token;
      state.errorMessage = null;
    },
    [login.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      localStorage.removeItem('authentication_token');
      state.errorMessage = payload?.message;
      state.loginSuccess = false;
      state.loading = false;
    },
    [otpAuthenticate.fulfilled.type]: (state, { payload }: PayloadAction<{ id_token: string }>) => {
      localStorage.setItem('authentication_token', payload.id_token);
      localStorage.removeItem('verify_type');
      state.token = payload.id_token;
      state.errorMessage = null;
    },
    [otpAuthenticate.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload?.message;
      state.loginSuccess = false;
      state.loading = false;
    },
    [register.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
      state.errorMessage = null;
      state.registerSuccess = true;
      state.loading = false;
    },
    [register.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload?.message;
      state.registerSuccess = false;
      state.loading = false;
    },
    [logout.fulfilled.type]: (state, { payload }: PayloadAction<undefined>) => {
      state.logoutSuccess = true;
      state.loading = false;
    },
    [logout.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [resetAuthentication.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.createAccountSuccess = false;
      state.loginSuccess = false;
      state.registerSuccess = false;
      state.getIdOtpSuccess = false;
      state.fileUrl = null;
      state.user = null;
      state.token = null;
      state.id_otp = null;
      state.errorMessage = payload;
      localStorage.removeItem('authentication_token');
      localStorage.removeItem('id_otp');
      localStorage.removeItem('tempRole');
      localStorage.removeItem('keycloak_authentication_token');
    },
    [authenticate.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
      state.loginSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [authenticate.rejected.type]: (state, { payload }) => {
      localStorage.removeItem('authentication_token');
      state.loginSuccess = false;
      state.loading = false;
    },
    [createAccount.fulfilled.type]: (state) => {
      state.createAccountSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [createAccount.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload?.message;
      state.createAccountSuccess = false;
      state.loading = false;
    },
    [verifyZaloOTP.fulfilled.type]: (state, { payload }) => {
      if (!state.user) return;
      state.user.zaloActivated = true;
      state.verifyOtpSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [verifyZaloOTP.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.verifyOtpSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [verifyZaloByNewPhone.fulfilled.type]: (state, { payload }: PayloadAction<string>) => {
      if (!state.user) return;
      state.user.phone = payload;
      state.user.zaloActivated = true;
      state.verifyOtpSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [verifyZaloByNewPhone.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.verifyOtpSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [sendOtpToCurrPhone.fulfilled.type]: (state, { payload }) => {
      state.sendOtpToCurrPhoneSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [sendOtpToCurrPhone.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [accountUpdate.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
      state.updateSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [accountUpdate.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload?.message;
      state.updateSuccess = false;
      state.loading = false;
    },
    [sendOtpToUpdatePhoneOrEmail.fulfilled.type]: (state, { payload }) => {
      state.sendOtpToCurrAndNewPhoneOrEmailSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [sendOtpToUpdatePhoneOrEmail.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [loginKeyCloak.fulfilled.type]: (state, { payload }: PayloadAction<{ id_token: string }>) => {
      localStorage.setItem('keycloak_authentication_token', payload.id_token);
      state.errorMessage = null;
      state.loading = false;
    },
    [loginKeyCloak.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      localStorage.removeItem('keycloak_authentication_token');
      // state.errorMessage = payload?.message;
      state.loading = false;
    },
  },
});

export default authenticationSlice.reducer;
export const {
  fetching,
  reset,
  softReset,
  changeTypeUser,
  changeTempRoles,
  storeTokenInStore,
  otpReset,
  storeUserInfo,
} = authenticationSlice.actions;
