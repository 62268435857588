

export enum ExchangeRateType{
  USD = "USD",
  VND = "VND",
  RUB = "RUB",
  GBP = "GBP",
  EUR = "EUR",
  CZK = "CZK",
  CNY = "CNY",
  AUD = "AUD",
}

export enum BlockchainNetwork {
  Bitcoin = "Bitcoin",
  ETH = "ETH",
}

export const blockchainNetworkArray: BlockchainNetwork[] = [BlockchainNetwork.Bitcoin, BlockchainNetwork.ETH];

// export const paymentTypeArray: PaymentType[] = [PaymentType.USD, PaymentType.VND, PaymentType.Bitcoin, PaymentType.ETH];
