import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../shared/reducers';
import {
  getEntities,
  createEntity,
  updateEntity,
  removeEntity,
  getEntity,
  updateEntityStatus,
  getPrimaryEntitiesByMasterId,
  getAlternativeAgencyForDisabledAgency,
} from './agencyManagement.api';
import { IInitialState } from '../../../../shared/shared-interfaces';
import { AxiosResponse } from 'axios';
import { IUser } from '../../../../shared/models/authentication.model';
import { Status } from '../../../../enumeration/status';

interface IAgencyManagementInititalState extends IInitialState {
  primaryAgencies: IUser[];
  alternativeAgencyForTheDisabled: IUser | null;
}

const initialState: IAgencyManagementInititalState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  primaryAgencies: [],
  alternativeAgencyForTheDisabled: null,
};

export const agencyManagementAdapter = createEntityAdapter<IUser>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'agencyManagementSlice',
  initialState: agencyManagementAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetPrimaryAgencies(state) {
      state.initialState.primaryAgencies = [];
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    setAll: agencyManagementAdapter.setAll,
    updateOne: agencyManagementAdapter.updateOne,
    addOne: agencyManagementAdapter.addOne,
    removeOne: agencyManagementAdapter.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<any>>) => {
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getPrimaryEntitiesByMasterId.fulfilled.type]: (state, { payload }: PayloadAction<IUser[]>) => {
      state.initialState.primaryAgencies = payload.filter(({ status }) => status === Status.ENABLE);
      state.initialState.loading = false;
    },
    [getPrimaryEntitiesByMasterId.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getAlternativeAgencyForDisabledAgency.fulfilled.type]: (state, { payload }: PayloadAction<IUser | null>) => {
      state.initialState.alternativeAgencyForTheDisabled = payload
      state.initialState.loading = false;
    },
    [getAlternativeAgencyForDisabledAgency.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state) => {
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntity.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [updateEntityStatus.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntityStatus.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
    [removeEntity.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    },
    [removeEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne, addOne, removeOne, resetPrimaryAgencies } = actions;

export const agencyManagementSelectors = agencyManagementAdapter.getSelectors<RootState>(
  (state) => state.agencyManagement
);

const { selectById } = agencyManagementAdapter.getSelectors();
const getagencyManagementState = (rootState: RootState) => rootState.agencyManagement;

export const selectEntityById = (id: string) => {
  return createSelector(getagencyManagementState, (state) => selectById(state, id));
};
