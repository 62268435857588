import { createSlice, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../shared/reducers';
import { getEntities, getEntity, updateEntityStatus } from './officeManagement.api';
import { IInitialState } from '../../../../shared/shared-interfaces';
import { AxiosResponse } from 'axios';
import { IUser } from '../../../../shared/models/authentication.model';
 
const initialState: IInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const officeManagementAdapter = createEntityAdapter<IUser>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'officeManagementSlice',
  initialState: officeManagementAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
    setAll: officeManagementAdapter.setAll,
    updateOne: officeManagementAdapter.updateOne,
    addOne: officeManagementAdapter.addOne,
    removeOne: officeManagementAdapter.removeOne,
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<any>>) => {
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<undefined>) => {
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
    [updateEntityStatus.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    },
    [updateEntityStatus.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity, setAll, updateOne, addOne, removeOne } = actions;

export const officeManagementSelectors = officeManagementAdapter.getSelectors<RootState>((state) => state.officeManagement);

const { selectById } = officeManagementAdapter.getSelectors();
const getofficeManagementState = (rootState: RootState) => rootState.officeManagement;

export const selectEntityById = (id: string) => {
  return createSelector(getofficeManagementState, (state) => selectById(state, id));
};
