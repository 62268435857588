import { ExchangeRateType } from '../enumeration/paymentType';
import { EnvEnum, _env } from '../shared/envDetect';

const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = '/api/';

// export const SERVER_API_URL = "https://dev.anft.vn/api/";

export const MANAGEMENT_SITE_URL =
  _env === EnvEnum.PROD
    ? 'https://anfteco.vn/api/'
    : _env === EnvEnum.DEV
    ? 'https://dev.anfteco.vn/api/'
    : 'http://localhost:4000/api/';

export const MANAGEMENT_FO_SITE_URL =
  _env === EnvEnum.PROD
    ? 'https://anfteco.vn/'
    : _env === EnvEnum.DEV
    ? 'https://dev.anfteco.vn/'
    : 'http://localhost:4000/';

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  MOBILE: 'MOBILE',
  ANONYMOUS: 'ROLE_ANONYMOUS',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'HH:mm - DD/MM/YY';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const GOOGLE_MAP_KEY = 'AIzaSyBBFYbIR5lqnbebJe66SCxgKQ6LjpmKAUw';
export const HTML_DATE_INPUT_FORMAT = 'YYYY-MM-DD';
export const HTML_DATE_TIME_INPUT_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DATE_SUBMIT_FORMAT = 'YYYY-MM-DD';
export const MAX_SUPPLY = 1232000000;

export const BSC_SCAN_URL = 'https://bscscan.com/token/';
export const SYSTEM_WALLET_ADDRESS = '0x8377bC5451F074C5578C28dB8A7C95415c721293';
export const BUSD_TOKEN_CONTRACT = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
export const USDT_TOKEN_CONTRACT = '0x55d398326f99059fF775485246999027B3197955';

export const MIN_COMMISSION_PERCENTAGE = 5;

export const firebaseConfig = {
  apiKey: 'AIzaSyB3Vn6PHb6wXYoy1uHFGXAmDXulII1t87I',
  authDomain: 'anft-39faf.firebaseapp.com',
  databaseURL: 'https://anft-39faf.firebaseio.com',
  projectId: 'anft-39faf',
  storageBucket: 'anft-39faf.appspot.com',
  messagingSenderId: '507171547637',
  appId: '1:507171547637:web:fbd8cb80175624801d18bc',
  measurementId: 'G-4ERYQFE3R8',
};

export const TOKEN_NAME = 'ANFT';
export const DOMESTIC_CURRENCY = ExchangeRateType.VND;
