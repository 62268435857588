import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../../../config/axios-interceptor';
import { INewRecruitmentPost, IRecruitment, IRecruitmentPost } from '../../../../../shared/models/recruiment.model';
import { uploadImage } from '../../../shared/ExpertBlogManagement/expertBlogs.api';
import { IRecruitmentsFilter } from './Recruitment';

const prefix = 'recruitments';
const postPrefix = "recruitment-posts"

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IRecruitmentsFilter, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IRecruitment[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${postPrefix}`, async (_, thunkAPI) => {
  try {
    const { data } = await axios.get<IRecruitmentPost>(`${postPrefix}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${postPrefix}`, async (body: IRecruitmentPost, thunkAPI) => {
  try {
    const {  thumbnail } = body;
    const thumbnailUrl = thumbnail ? await uploadImage(thumbnail) : undefined;
    const requestBody = {
      ...body,
      thumbnail: thumbnailUrl,
    };
    const { data } = await axios.post<IRecruitmentPost>(`${postPrefix}`, requestBody);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-new-${postPrefix}`, async (body: INewRecruitmentPost, thunkAPI) => {
  try {
    const {  thumbnail } = body;
    const thumbnailUrl = thumbnail ? await uploadImage(thumbnail) : undefined;
    const requestBody = {
      ...body,
      thumbnail: thumbnailUrl,
    };
    const { data } = await axios.post<INewRecruitmentPost>(`${postPrefix}`, requestBody);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
