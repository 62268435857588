import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "../../../../config/axios-interceptor";
import { IAccount, INewAccount } from "../../../../shared/models/admin-account.model";
import { addOne, removeOne, setAll, updateOne } from "./account.reducer";
import { ICCFilter } from "./staff/Admin";

const prefix = "users";

// export const getEntities = createAsyncThunk('get-groups', async (params: IGroupFilterState, thunkAPI) => {
export const getEntities = createAsyncThunk("admin-get-users", async (field: ICCFilter, thunkAPI) => {
  try {
    //   const result = await axios.get(`${prefix}`, { params });
    const params = _.pickBy(field);
    const result = await axios.get<IAccount[]>(`${prefix}`, { params });
    thunkAPI.dispatch(setAll(result.data));
    return result;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk("admin-get-user", async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IAccount>(`users/${id}`);
    const resultArray = [result.data];
    thunkAPI.dispatch(setAll(resultArray));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAccountById = createAsyncThunk("getAccountById", async (id: string, thunkAPI) => {
  try {
    const result = await axios.get<IAccount>(`users/${id}`);
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk("admin-update-user", async (body: IAccount, thunkAPI) => {
  try {
    const { id } = body;
    const result = await axios.put(`${prefix}/${id}`, body);
    if (id) {
      thunkAPI.dispatch(updateOne({ id, changes: result.data }));
    }
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk("admin-create-user", async (body: INewAccount, thunkAPI) => {
  try {
    const result = await axios.post(`${prefix}`, body);
    const { id } = result.data;
    thunkAPI.dispatch(addOne({ id, ...result.data }));
    return result.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const removeEntity = createAsyncThunk("admin-delete-user", async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    thunkAPI.dispatch(removeOne(id));
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
