import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState } from '../../../../shared/shared-interfaces';
import { createEntity, getEntity, IConfigFee } from './configfee.api';

interface IConfigFeeInitialState extends IInitialState {
  fee: string;
}

const initialState: IConfigFeeInitialState = {
  fee: '',
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

const { actions, reducer } = createSlice({
  name: 'configFeeSlice',
  initialState: initialState,
  reducers: {
    fetching(state) {
      state.loading = true;
    },
    resetAll(state) {
      state.fee = '';
      state.loading = false;
      state.fetchEntitiesSuccess = false;
      state.fetchEntitySuccess = false;
      state.updateEntitySuccess = false;
      state.deleteEntitySuccess = false;
      state.errorMessage = null;
    },
    resetEntity(state) {
      state.updateEntitySuccess = false;
      state.errorMessage = null;
      state.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [createEntity.fulfilled.type]: (state, { payload }: PayloadAction<IConfigFee>) => {
      state.fee = payload.fee;
      state.updateEntitySuccess = true;
      state.loading = false;
    },
    [createEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.updateEntitySuccess = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IConfigFee>) => {
      state.fee = payload.fee;
      state.fetchEntitySuccess = true;
      state.loading = false;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.fetchEntitySuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;
