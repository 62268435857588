import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../config/axios-interceptor';
import { IAgncyInvstrStats } from '../../../../shared/models/statisticStats.model';


const prefix = 'users/growth';

export const getAgencyInvestorStatistics = createAsyncThunk(`get-${prefix}`, async (_, thunkAPI) => {
  try {
    const {data} = await axios.get<IAgncyInvstrStats>(`${prefix}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});