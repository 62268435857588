import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IAsset, IAssetOverview } from '../../../../shared/models/asset.model';
import { getAssets, getAssetOverview } from './asset.api';

interface IInitialState {
  fetchEntitiesSuccess: boolean;
  totalItems: number;
  errorMessage: string | null;
  loading: boolean;
  entities: IAsset[]; 
  overview: IAssetOverview | null;
}

const initialState: IInitialState = {
  fetchEntitiesSuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  entities: [],
  overview: null,
};

const {reducer, actions} = createSlice({
  name: 'assetSlice',
  initialState,
  reducers: {
    fetching: (state) => {
      state.loading = true;
    },
    resetEntity: (state) => {
      state.loading = false;
      state.errorMessage = null;
    },
    resetAll: (state) => {
      state = initialState;
    },
  },
  extraReducers: {
    [getAssets.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse< IAsset[]>>) => {
      state.fetchEntitiesSuccess = true;
      state.loading = false;
      state.entities = payload.data;
      state.totalItems = Number(payload.headers['x-total-count']);
    },
    [getAssets.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.fetchEntitiesSuccess = false;
    },
    [getAssetOverview.fulfilled.type]: (state, { payload }: PayloadAction<IAssetOverview>) => {
      state.fetchEntitiesSuccess = true;
      state.loading = false;
      state.overview = payload;
    },
    [getAssetOverview.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.errorMessage = payload?.message;
      state.loading = false;
      state.fetchEntitiesSuccess = false;
    },
  
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity } = actions;
