import { IAssetOverview, IRawAssetOverview } from '../../../../shared/models/asset.model';

// Since this API return a float string, therefore we have to convert them manually
export const convertToFloat = (overview: IRawAssetOverview): IAssetOverview => {
  const { companyToAgency, totalParentCashback, totalChildrenCashback, totalAgencyBonusInvestor, totalMasterBonus } =
    overview;

  return {
    ...overview,
    companyToAgency: parseFloat(companyToAgency),
    totalParentCashback: parseFloat(totalParentCashback),
    totalChildrenCashback: parseFloat(totalChildrenCashback),
    totalAgencyBonusInvestor: parseFloat(totalAgencyBonusInvestor),
    totalMasterBonus: parseFloat(totalMasterBonus),
  };
};
