import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RequestStatus } from '../../../../enumeration/request';
import { IExpert } from '../../../../shared/models/expert.model';
import { RootState } from '../../../../shared/reducers';
import {
  approveExpert,
  createEntities,
  getEntities,
  getEntitiesHistory,
  getEntity, publicEntity, updateEntities
} from './expert.api';

interface IInitialState {
  fetchSuccess: boolean;
  fetchEntitySuccess: boolean;
  fetchHistories: boolean;
  updateExpertSuccess: boolean;
  errorMessage: string | null;
  loading: boolean;
  entities: IExpert[];
  entity: IExpert | null;
  histories: IExpert[];
  createExpertSuccess: boolean;
  approveExpertSuccess: boolean;
  totalItems: number;
}

const initialState: IInitialState = {
  totalItems: 0,
  fetchHistories: false,
  updateExpertSuccess: false,
  fetchEntitySuccess: false,
  fetchSuccess: false,
  loading: false,
  entity: null,
  errorMessage: null,
  histories: [],
  entities: [],
  createExpertSuccess: false,
  approveExpertSuccess: false,
};

export const expertAdapter = createEntityAdapter<IExpert>({
  selectId: ({ id }) => id,
});

const { reducer, actions } = createSlice({
  name: 'expertSlice',
  initialState: expertAdapter.getInitialState({ initialState }),
  reducers: {
    fetching: (state) => {
      state.initialState.loading = true;
    },
    resetEntity: (state) => {
      state.initialState.loading = false;
      state.initialState.errorMessage = null;
      state.initialState.fetchSuccess = false;
      state.initialState.fetchHistories = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateExpertSuccess = false;
      state.initialState.createExpertSuccess = false;
      state.initialState.approveExpertSuccess = false;
    },
    resetAll: (state) => {
      state.initialState = initialState;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IExpert[]>>) => {
      expertAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers['x-total-count']);
      state.initialState.loading = false;
      state.initialState.fetchSuccess = true;
      state.initialState.entities = payload.data;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchSuccess = false;
    },
    [getEntitiesHistory.fulfilled.type]: (state, { payload }: PayloadAction<IExpert[]>) => {
      state.initialState.loading = false;
      state.initialState.fetchHistories = true;
      state.initialState.histories = payload;
    },
    [getEntitiesHistory.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchHistories = false;
    },
    [getEntity.fulfilled.type]: (state, { payload }: PayloadAction<IExpert>) => {
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = true;
      state.initialState.entity = payload;
    },
    [getEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    },
    [updateEntities.fulfilled.type]: (state, { payload }: PayloadAction<IExpert>) => {
      state.initialState.updateExpertSuccess = true;
      state.initialState.loading = false;
    },
    [updateEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateExpertSuccess = false;
    },
    [createEntities.fulfilled.type]: (state, { payload }: PayloadAction<IExpert>) => {
      state.initialState.loading = false;
      state.initialState.createExpertSuccess = true;
    },
    [createEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.createExpertSuccess = false;
    },
    [approveExpert.fulfilled.type]: (state, { payload }: PayloadAction<IExpert>) => {
      state.initialState.loading = false;
      state.initialState.approveExpertSuccess = true;
    },
    [approveExpert.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.approveExpertSuccess = false;
    },
    [publicEntity.fulfilled.type]: (state, { payload }: PayloadAction<IExpert>) => {
      expertAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateExpertSuccess = true;
      state.initialState.loading = false;
    },
    [publicEntity.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateExpertSuccess = false;
    },
  },
});

export default reducer;
export const { fetching, resetAll, resetEntity } = actions;

export const expertSelectors = expertAdapter.getSelectors<RootState>((state) => state.expert);

const getExpertState = (rootState: RootState) => rootState.expert;

export const checkSentExpert = () => {
  return createSelector(getExpertState, (state) => {
    if (!state.initialState.histories.length) return undefined;
    return Boolean(state.initialState.histories.find(({ status }) => status !== RequestStatus.REJECT));
  });
};
