import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IUser } from '../../../shared/models/authentication.model';
import { ITransferHistory } from '../../../shared/models/frontOfficeDashboard.model';
import { IOrder } from '../../../shared/models/order.model';
import { IStage } from '../../../shared/models/stage.model';
import { IRemUser } from '../../../shared/models/userRem.model';
import { IWithdrawTransaction } from '../../../shared/models/withdrawTransaction.model';
import { IInvestorConfig } from '../../backOffice/Agency/investorConfigPolicy/investorConfigPolicy.model';
import {
  accountUpdate,
  anftOrder,
  changeLanguage,
  changePassword,
  getAccountInfo,
  getCurrentStages,
  getDiscountPolicy,
  getListingTypesRealEstateMgmt,
  getOrderHistory,
  getRealEstateMgmtRegistrationConfig,
  getUserByPhone,
  getUserCurrentAnft,
  getWithdrawAnftHistory,
  IListingType,
  ordersCheck,
  realEstateMgmtAccount,
  registerRealEstateMgmt,
  transactionsCheck,
  transferAnft,
  transferAnftHistory,
  userAffiliate,
  withdrawAnft,
  withdrawAuthenticate,
} from './dashboard.api';
import { IActivityWithConfig } from './RealEstateMgmtRegistrationModal';

interface IResponse<T> {
  count: number;
  results: T;
}

interface IANFTAmount {
  tokens: number;
  bonusTokens: number;
}
interface IAuthenticationState {
  getWithdrawHistorySuccess: boolean;
  withDrawHistory: IWithdrawTransaction[];
  withDrawHistoryTotal: number;
  withDrawOrderId: string | null;
  errorWithDrawMessage: string | null;
  withdrawAuthenticateSuccess: boolean;
  withDrawAnftSuccess: boolean;
  getAccountInfoSuccess: boolean;
  checkTransaction: boolean | null;
  checkTransactionSuccess: boolean;
  checkOrders: boolean | null;
  checkOrdersSuccess: boolean;
  getUserCurrentAnftSuccess: boolean;
  userCurrentAnft: IANFTAmount | null;
  getCurrentStateSucces: boolean;
  currentStages: IStage | null;
  getDiscountPolicySuccess: boolean;
  discountPolicy: IInvestorConfig | null;
  orderSuccess: boolean;
  changeLanguageSuccess: boolean;
  transferAnftSuccess: boolean;
  loading: boolean;
  loadingCurrToken: boolean;
  referencerListUser: Array<IUser>;
  changePasswordSuccess: boolean;
  getListReferencerUser: boolean;
  updateAccountSucess: boolean;
  dashboardUser: IUser | null;
  userByPhone: IUser | null;
  userByPhoneSuccess: boolean;
  userByPhoneError: string | null;
  errorMessage: string | null;
  transferAnftHistoryList: Array<ITransferHistory>;
  transferAnftHistoryTotal: number;
  transferAnftHistorySuccess: boolean;
  orderHistory: IOrder[];
  orderHistoryTotal: number;
  orderHistorySuccess: boolean;
  realEstateMgmtRegistrationConfig: IActivityWithConfig[];
  getRealEstateMgmtRegistrationConfigSuccess: boolean;
  registerRealEstateMgmtSuccess: boolean;
  getRealEstateUserSuccess: boolean;
  realEstateUser: IRemUser | null;
  listingTypes: IListingType[];
  getListingTypesSuccess: boolean;
}

const initialState: IAuthenticationState = {
  withDrawHistoryTotal: 0,
  getWithdrawHistorySuccess: false,
  withDrawHistory: [],
  errorWithDrawMessage: null,
  withDrawOrderId: null,
  withdrawAuthenticateSuccess: false,
  withDrawAnftSuccess: false,
  getAccountInfoSuccess: false,
  checkTransaction: null,
  checkTransactionSuccess: false,
  checkOrders: null,
  checkOrdersSuccess: false,
  getUserCurrentAnftSuccess: false,
  userCurrentAnft: null,
  getCurrentStateSucces: false,
  currentStages: null,
  getDiscountPolicySuccess: false,
  discountPolicy: null,
  orderSuccess: false,
  transferAnftSuccess: false,
  changeLanguageSuccess: false,
  getListReferencerUser: false,
  updateAccountSucess: false,
  changePasswordSuccess: false,
  userByPhone: null,
  userByPhoneSuccess: false,
  dashboardUser: null,
  referencerListUser: [],
  loading: false,
  loadingCurrToken: false,
  userByPhoneError: null,
  errorMessage: null,
  transferAnftHistoryList: [],
  transferAnftHistorySuccess: false,
  transferAnftHistoryTotal: 0,
  orderHistory: [],
  orderHistoryTotal: 0,
  orderHistorySuccess: false,
  realEstateMgmtRegistrationConfig: [],
  getRealEstateMgmtRegistrationConfigSuccess: false,
  registerRealEstateMgmtSuccess: false,
  getRealEstateUserSuccess: false,
  realEstateUser: null,
  listingTypes: [],
  getListingTypesSuccess: false,
};

// export type IAuthentication = Readonly<typeof initialState>;

const userDashboardSlice = createSlice({
  name: 'userDashboardSlice',
  initialState,
  reducers: {
    fetching(state) {
      state.loading = true;
    },
    reset(state) {
      state.getWithdrawHistorySuccess = false;
      state.withDrawHistory = [];
      state.errorWithDrawMessage = null;
      state.withDrawOrderId = null;
      state.withdrawAuthenticateSuccess = false;
      state.withDrawAnftSuccess = false;
      state.checkTransaction = null;
      state.checkTransactionSuccess = false;
      state.checkOrders = null;
      state.checkOrdersSuccess = false;
      state.changePasswordSuccess = false;
      state.getUserCurrentAnftSuccess = false;
      state.userCurrentAnft = null;
      state.currentStages = null;
      state.getCurrentStateSucces = false;
      state.discountPolicy = null;
      state.getDiscountPolicySuccess = false;
      state.orderSuccess = false;
      state.loading = false;
      state.errorMessage = null;
      state.getListReferencerUser = false;
      state.changePasswordSuccess = false;
      state.referencerListUser = [];
      state.updateAccountSucess = false;
      state.changeLanguageSuccess = false;
      state.userByPhoneSuccess = false;
      state.userByPhone = null;
      state.dashboardUser = null;
      state.userByPhoneError = null;
      state.transferAnftSuccess = false;
      state.transferAnftHistoryList = [];
      state.transferAnftHistorySuccess = false;
      state.orderHistory = [];
      state.orderHistorySuccess = false;
      state.getAccountInfoSuccess = false;
      state.getRealEstateUserSuccess = false;
      state.realEstateUser = null;
    },
    resetEntity(state) {
      state.getWithdrawHistorySuccess = false;
      state.withDrawHistory = [];
      state.errorWithDrawMessage = null;
      state.withDrawOrderId = null;
      state.withdrawAuthenticateSuccess = false;
      state.withDrawAnftSuccess = false;
      state.getUserCurrentAnftSuccess = false;
      state.checkOrdersSuccess = false;
      state.checkTransactionSuccess = false;
      state.getCurrentStateSucces = false;
      state.getDiscountPolicySuccess = false;
      state.orderSuccess = false;
      state.errorMessage = null;
      state.loading = false;
      state.getAccountInfoSuccess = false;
      state.getListReferencerUser = false;
      state.changePasswordSuccess = false;
      state.updateAccountSucess = false;
      state.changeLanguageSuccess = false;
      state.userByPhoneSuccess = false;
      state.transferAnftSuccess = false;
      state.transferAnftHistorySuccess = false;
      state.orderHistorySuccess = false;
    },
    resetUserByPhone(state) {
      state.userByPhone = null;
      state.userByPhoneError = null;
      state.userByPhoneSuccess = false;
      state.transferAnftSuccess = false;
    },
    resetTransferHistory(state) {
      state.transferAnftHistoryList = [];
      state.transferAnftHistorySuccess = false;
      state.errorMessage = null;
    },
    fetchingCurrToken(state) {
      state.loadingCurrToken = true;
    },
    resetWithDraw(state) {
      state.withDrawAnftSuccess = false;
      state.errorWithDrawMessage = null;
      state.withdrawAuthenticateSuccess = false;
    },
    setNewPhone(state, { payload }: PayloadAction<string>) {
      if (!state.dashboardUser) return;
      state.dashboardUser.phone = payload;
    },
    setPhoneVerified(state) {
      if (!state.dashboardUser) return;
      state.dashboardUser.zaloActivated = true;
    },
    resetRegisterRealEstateMgmt(state) {
      state.registerRealEstateMgmtSuccess = false;
    },
    setTokenLocked(state, { payload }: PayloadAction<number>) {
      if (!state.dashboardUser) return;
      state.dashboardUser.lockingAmountRequired = (state.dashboardUser.lockingAmountRequired || 0) + payload;
    },
  },
  extraReducers: {
    [anftOrder.fulfilled.type]: (state) => {
      state.errorMessage = null;
      state.orderSuccess = true;
      state.loading = false;
    },
    [anftOrder.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload?.message;
      state.orderSuccess = false;
      state.loading = false;
    },
    [changePassword.fulfilled.type]: (state) => {
      state.errorMessage = null;
      state.changePasswordSuccess = true;
      state.loading = false;
    },
    [changePassword.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.errorMessage = payload?.message;
      state.changePasswordSuccess = false;
      state.loading = false;
    },
    [userAffiliate.fulfilled.type]: (state, { payload }: PayloadAction<IUser[]>) => {
      state.referencerListUser = payload;
      state.getListReferencerUser = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [userAffiliate.rejected.type]: (state, { payload }) => {
      state.getListReferencerUser = false;
      state.loading = false;
    },
    [accountUpdate.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.dashboardUser = payload;
      state.updateAccountSucess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [accountUpdate.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.updateAccountSucess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [changeLanguage.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.changeLanguageSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [changeLanguage.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.changeLanguageSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getUserByPhone.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.userByPhone = payload;
      state.userByPhoneSuccess = true;
      state.userByPhoneError = null;
      state.loading = false;
    },
    [getUserByPhone.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.userByPhoneSuccess = false;
      state.userByPhoneError = payload?.message;
      state.loading = false;
    },
    [transferAnft.fulfilled.type]: (state, { payload }) => {
      state.transferAnftSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [transferAnft.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.transferAnftSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [transferAnftHistory.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<ITransferHistory[]>>) => {
      state.transferAnftHistoryList = payload.data;
      state.transferAnftHistoryTotal = Number(payload.headers['x-total-count']);
      state.transferAnftHistorySuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [transferAnftHistory.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.transferAnftHistorySuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getOrderHistory.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IOrder[]>>) => {
      state.orderHistory = payload.data;
      state.orderHistoryTotal = Number(payload.headers['x-total-count']);
      state.orderHistorySuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [getOrderHistory.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.orderHistorySuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getDiscountPolicy.fulfilled.type]: (state, { payload }: PayloadAction<IInvestorConfig>) => {
      state.discountPolicy = payload;
      state.getDiscountPolicySuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [getDiscountPolicy.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.getDiscountPolicySuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getCurrentStages.fulfilled.type]: (state, { payload }: PayloadAction<IStage>) => {
      state.currentStages = payload;
      state.getCurrentStateSucces = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [getCurrentStages.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.getCurrentStateSucces = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getUserCurrentAnft.fulfilled.type]: (state, { payload }: PayloadAction<IANFTAmount>) => {
      state.userCurrentAnft = payload;
      state.getUserCurrentAnftSuccess = true;
      state.errorMessage = null;
      state.loading = false;
      state.loadingCurrToken = false;
    },
    [getUserCurrentAnft.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.getUserCurrentAnftSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
      state.loadingCurrToken = false;
    },
    [transactionsCheck.fulfilled.type]: (state, { payload }: PayloadAction<boolean>) => {
      state.checkTransaction = payload;
      state.checkTransactionSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [transactionsCheck.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.checkTransactionSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [ordersCheck.fulfilled.type]: (state, { payload }: PayloadAction<boolean>) => {
      state.checkOrders = payload;
      state.checkOrdersSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [ordersCheck.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.checkOrdersSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getAccountInfo.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.dashboardUser = payload;
      state.getAccountInfoSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [getAccountInfo.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.getAccountInfoSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [withdrawAnft.fulfilled.type]: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.withDrawOrderId = payload.id;
      state.withDrawAnftSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [withdrawAnft.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.withDrawAnftSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [withdrawAuthenticate.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.withdrawAuthenticateSuccess = true;
      state.errorWithDrawMessage = null;
      state.loading = false;
    },
    [withdrawAuthenticate.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.withdrawAuthenticateSuccess = false;
      state.errorWithDrawMessage = payload?.message;
      state.loading = false;
    },
    [getWithdrawAnftHistory.fulfilled.type]: (
      state,
      { payload }: PayloadAction<AxiosResponse<IWithdrawTransaction[]>>
    ) => {
      state.withDrawHistory = payload.data;
      state.withDrawHistoryTotal = Number(payload.headers['x-total-count']);
      state.getWithdrawHistorySuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [getWithdrawAnftHistory.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.getWithdrawHistorySuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getRealEstateMgmtRegistrationConfig.fulfilled.type]: (
      state,
      { payload }: PayloadAction<AxiosResponse<IResponse<IActivityWithConfig[]>>>
    ) => {
      state.realEstateMgmtRegistrationConfig = payload.data.results;
      state.getRealEstateMgmtRegistrationConfigSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [getRealEstateMgmtRegistrationConfig.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.getRealEstateMgmtRegistrationConfigSuccess = false;
      // state.errorMessage = payload?.message;
      state.loading = false;
    },
    [registerRealEstateMgmt.fulfilled.type]: (state, { payload }) => {
      state.registerRealEstateMgmtSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [registerRealEstateMgmt.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.registerRealEstateMgmtSuccess = false;
      state.errorMessage = payload?.message;
      state.loading = false;
    },
    [realEstateMgmtAccount.fulfilled.type]: (state, { payload }: PayloadAction<IRemUser>) => {
      state.realEstateUser = payload;
      state.getRealEstateUserSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [realEstateMgmtAccount.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      localStorage.removeItem('keycloak_authentication_token');
      state.getRealEstateUserSuccess = false;
      // state.errorMessage = payload?.message;
      state.loading = false;
    },
    [getListingTypesRealEstateMgmt.fulfilled.type]: (
      state,
      { payload }: PayloadAction<AxiosResponse<IResponse<IListingType[]>>>
    ) => {
      state.listingTypes = payload.data.results;
      state.getListingTypesSuccess = true;
      state.errorMessage = null;
      state.loading = false;
    },
    [getListingTypesRealEstateMgmt.rejected.type]: (state, { payload }: PayloadAction<{ message: string }>) => {
      state.getListingTypesSuccess = false;
      // state.errorMessage = payload?.message;
      state.loading = false;
    },
  },
});

export default userDashboardSlice.reducer;
export const {
  fetching,
  reset,
  resetEntity,
  resetUserByPhone,
  fetchingCurrToken,
  resetTransferHistory,
  resetWithDraw,
  setNewPhone,
  setPhoneVerified,
  resetRegisterRealEstateMgmt,
  setTokenLocked,
} = userDashboardSlice.actions;
