import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilPlus,
  cilBan,
  cilCheckCircle,
  cilXCircle,
  cilX,
  cilBank,
  cilBook,
} from '@coreui/icons/js/free';

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilPlus,
    cilBan,
    cilCheckCircle,
    cilXCircle,
    cilX,
    cilBank,
    cilBook,
  }
);
