import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ISystemGrowth } from "../../../../shared/models/system-growth.model";
import { RootState } from "../../../../shared/reducers";
import { IInitialState } from "../../../../shared/shared-interfaces";
import { getEntities } from "./systemGrowth.api";

const initialState: IInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
};

export const systemGrowthAdapter = createEntityAdapter<ISystemGrowth>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: "systemGrowthSlice",
  initialState: systemGrowthAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: {
    [getEntities.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<ISystemGrowth[]>>) => {
      systemGrowthAdapter.setAll(state, payload.data);
      state.initialState.totalItems = Number(payload.headers["total-user-count"]);
      state.initialState.fetchEntitiesSuccess = true;
      state.initialState.loading = false;
    },
    [getEntities.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const systemGrowthSelectors = systemGrowthAdapter.getSelectors<RootState>((state) => state.systemGrowth);

const { selectById } = systemGrowthAdapter.getSelectors();
const getSystemGrowthState = (rootState: RootState) => rootState.systemGrowth;

export const selectEntityById = (id: string) => {
  return createSelector(getSystemGrowthState, (state) => selectById(state, id));
};

